import Vue from 'vue';
import { imageUrl } from './image';
import moment from 'moment-timezone';

Vue.filter('imageUrl', (value, size) => {
	if (!value) return '';
	return imageUrl(value, size);
});

Vue.filter('formatTime', value => {
	if (!value) return '';
	return value.slice(0, 5);
});

Vue.filter('formatDate', value => {
	if (!value) return '';

	const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
	const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	const tempdate = new Date(value);
	const date = new Date(tempdate.getTime() + tempdate.getTimezoneOffset() * 60000);

	return `${dayNames[date.getDay()]}, ${date.getDate()} ${monthNames[date.getMonth()]}`;
});

Vue.filter('formatDateTime', value => {
	if (!value) return '';
	return new Date(value).toLocaleString('en-US', {
		day: 'numeric',
		month: 'short',
		year: 'numeric',
		hour12: false,
	});
});

Vue.filter('dateTextFormat', (time, date) => {
	return moment.tz(`${date} ${time}`, 'CET').format('D MMMM');
});

Vue.filter('timeFormat', (time, date) => {
	return moment.tz(`${date} ${time}`, 'CET').format('H:mm A');
});

Vue.filter('calendarTimeFormat', (date) => {
	return moment(date).format('HH:mm');
});

Vue.filter('dayFormat', date => {
	return moment(date).format('dddd, D MMMM');
});

Vue.filter('systemDateTimeFormat', (date) => {
	return moment.tz(date, 'CET').format('YYYY-MM-DD HH:mm');
});

Vue.filter('localTimeFormat', (time, date) => {
	let target = moment.tz.guess();
	return moment
		.tz(`${date} ${time}`, 'CET')
		.tz(target)
		.format('H:mm A');
});

Vue.filter('utcTimeFormat', (date) => {
	let target = moment.tz.guess();
	return moment(date)
		.tz(target)
		.format('H:mm');
});

Vue.filter('localTimeDateFormat', (time, date) => {
	let target = moment.tz.guess();
	let congressDay = moment.tz(`${date} ${time}`, 'CET').dayOfYear();
	let targetDay = moment.tz(`${date} ${time}`, 'CET').tz(target).dayOfYear();

	if (congressDay !== targetDay) {
		return moment
			.tz(`${date} ${time}`, 'CET')
			.tz(target)
			.format('dddd, D MMMM - H:mm A');
	} else {
		return moment
			.tz(`${date} ${time}`, 'CET')
			.tz(target)
			.format('H:mm A');
	}
});

Vue.filter('localTimeDateOnlyFormat', (time, date) => {
	let target = moment.tz.guess();
	return moment
		.tz(`${date} ${time}`, 'CET')
		.tz(target)
		.format('dddd, D MMMM');
});
