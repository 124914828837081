var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"notification"},[(_vm.toast)?_c('button',{staticClass:"delete",on:{"click":function($event){return _vm.$emit('notification:hide')}}}):_vm._e(),(_vm.data.image && _vm.data.image.url)?_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":_vm._f("imageUrl")(_vm.data.image),"alt":_vm.data.title}})]):_vm._e(),_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.data.title))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.data.description))]),(_vm.data.call_to_action)?_c('Link',{staticClass:"cta button",attrs:{"link":_vm.data.call_to_action,"external-warning":true,"analytics":{
				event: 'gaEvent',
				eCategory: 'No Booth Event',
				eAction: 'Notification',
				eLabel: _vm.data.title ? _vm.data.title : _vm.data.call_to_action ? _vm.data.call_to_action.text : null,
				Exhibitor: _vm.data.gtm_exhibitor ? _vm.data.gtm_exhibitor : '(not set)',
				Booth: '(not set)',
			}},on:{"link:click":function($event){return _vm.$emit('notification:link:click')}}},[_vm._v(" "+_vm._s(_vm.data.call_to_action.text || 'Click here')+" ")]):_vm._e()],1),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }