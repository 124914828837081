<template>
	<div :class="{ banner: isLink || data.content_modal }">
		<template v-if="isLink">
			<component :is="link ? 'Link' : 'span'" :link="link" :external-warning="true" :analytics="analytics" :class="{ 'is-restricted': data.restricted }" @click="actionClick(data)">
				<img :src="data.image | imageUrl" :alt="link ? link.text : data.name" />
			</component>
		</template>
		<template v-else-if="data.content_modal">
			<div @click="showModal">
				<img :src="data.image | imageUrl" :alt="data.link ? data.link.text : data.name" />
			</div>
		</template>
		<template v-else>
			<img :src="data.image | imageUrl" :alt="data.link ? data.link.text : data.name" />
		</template>
	</div>
</template>

<script>
	import Link from '@/components/Link';
	import ContentModalDynamic from '@/components/ContentModalDynamic';

	export default {
		name: 'BoothBanner',
		components: { Link, ContentModalDynamic },
		props: {
			data: {
				type: Object,
				required: true,
			},
			boothTitle: {
				type: String,
				default: '',
			},
			analytics: {
				type: Object,
				default: () => {},
			},
		},
		data: function() {
			return {};
		},
		computed: {
			isLink() {
				return this.data.link || (this.data.content_modal && (this.data.content_modal.type === 'link_external' || this.data.content_modal.type === 'safelink'));
			},
			link() {
				if (this.data.link) return this.data.link;
				if (this.data.content_modal && (this.data.content_modal.type === 'link_external' || this.data.content_modal.type === 'safelink')) return this.data.content_modal;
				return false;
			},
		},
		methods: {
			actionClick(activity) {
				if (activity.restricted === true) {
					this.$modal.show('restrict-modal');
					window.setTimeout(() => {
						this.$modal.hide('restrict-modal');
					}, 3500);
				}
			},
			showModal() {
				this.$vfm.show({
					component: ContentModalDynamic,
					bind: {
						element: this.data.content_modal,
					},
					on: {
						confirm(close) {
							close();
						},
						cancel(close) {
							close();
						},
					},
				});
			},
		},
	};
</script>
