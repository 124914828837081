<template>
	<modal name="card-exchange" :adaptive="true" width="90%" :max-width="700" height="auto" :reset="true" @before-open="beforeOpen">
		<div class="card-exchange-modal">
			<h1>Business Card Exchange</h1>
			<p></p>
			<div v-if="!submitted">
				<textarea v-model="message" class="textarea" placeholder="Provide a message to the Exhibitor..."></textarea>

				<label class="card-checkbox">
					<span class="input-wrapper">
						<input v-model="gdpr" type="checkbox" />
					</span>
					<span>
						You understand and acknowledge that your personal data (name, address, contact details, country, etc.) will be shared with the
						participating/exhibiting industry for the purpose of these functions on the digital platform and used in accordance to the General Data Protection
						Regulation (GDPR).<br /><br />By using these features to collaborate and communicate with the industry, you accept that EAACI, its Board of
						Officers, Executive Committee Members and employees shall not be liable for any communication or content shared.
					</span>
				</label>

				<div class="buttons">
					<button
						class="button is-primary"
						:disabled="!gdpr || !message || message.length < 1 || submitting"
						:class="{ 'is-loading': submitting }"
						@click.prevent="submit"
					>
						Yes, proceed
					</button>
					<a class="button is-primary is-outlined" @click.prevent="cancel()">No, cancel</a>
				</div>
			</div>

			<div v-if="submitted">
				<p>Your business card was successfully shared. An Industry Representative will be in contact with you soon.</p>
				<div class="buttons">
					<a class="button is-primary is-outlined" @click.prevent="cancel()">Close</a>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import $http from '../utils/http';

	export default {
		name: 'CardExchange',
		data() {
			return {
				id: null,
				message: '',
				gdpr: false,
				submitting: false,
				submitted: false,
			};
		},
		methods: {
			beforeOpen(event) {
				this.id = event.params.id;
			},
			submit() {
				this.submitting = true;
				$http
					.post('/business-cards', {
						booth: this.id,
						message: this.message,
						gdpr: this.gdpr,
					})
					.then(data => {
						this.submitting = false;
						this.submitted = true;
						this.message = '';
					});
			},
			cancel() {
				this.submitting = false;
				this.submitted = false;
				this.message = '';
				this.gdpr = false;
				this.$modal.hide('card-exchange');
			},
		},
	};
</script>

<style scoped></style>
