<template>
	<div v-show="video && visible" v-if="visible" class="video-ad" :class="{ 'is-square': square }">
		<div class="video-ad-player">
			<video ref="adPlayer" class="video-js vjs-fill vjs-big-play-centered"></video>
		</div>
		<Link ref="videoLink" :link="video && video.link" :analytics="analytics">
			<div v-if="video" class="video-ad-title">
				<span>{{ video.title }}</span>
			</div>
		</Link>
		<div class="video-ad-close">
			<a v-if="countdown === 0" @click.prevent="hideVideo">Close ad</a>
			<span v-else>{{ countdown }}</span>
		</div>
	</div>
</template>

<script>
	import $http from '../utils/http.js';
	import videojs from 'video.js';
	import Link from './Link';

	export default {
		name: 'VideoAds',
		components: { Link },
		data() {
			return {
				video: null,
				visible: true,
				player: null,
				countdown: 6,
				interval: null,
				square: false,
			};
		},
		computed: {
			analytics() {
				if (!this.video) return {};
				return {
					event: 'gaEvent',
					eCategory: 'No Booth Event',
					eAction: 'Video Ad',
					eLabel: this.video.title ? this.video.title : this.video.link ? this.video.link.url : null,
					Exhibitor: this.video.gtm_exhibitor ? this.video.gtm_exhibitor : '(not set)',
					Booth: '(not set)',
				};
			},
		},
		mounted() {
			this.countdown = 6;
			$http.get('video-ads').then(
				data => {
					this.visible = data.data.active;
					const ads = data.data.video_ad;
					this.video = ads[Math.floor(Math.random() * ads.length)];
					let options = {
						autoplay: true,
						loop: true,
						controls: false,
						fill: true,
						muted: true,
						sources: this.video && this.video.file && [{ type: this.video.file.mime, src: this.$options.filters.imageUrl(this.video.file) }],
					};
					this.player = videojs(this.$refs.adPlayer, options);
					let bpb = this.player.getChild('bigPlayButton');
					if (bpb) {
						bpb.hide();
					}
					this.player.ready(() => {
						this.interval = window.setInterval(() => this.doCountdown(), 1000);
						if (bpb) {
							let promise = this.player.play();
							if (promise === undefined) {
								bpb.show();
							} else {
								promise.then(
									() => {
										bpb.show();
									},
									() => {
										bpb.show();
									}
								);
							}
						}
						this.player.tech_.off('mousedown');
						this.player.tech_.on('mousedown', e => {
							e.preventDefault();
							this.$refs.videoLink.$el.click();
						});

						this.player.tech_.on('loadedmetadata', () => {
							window.player = this.player;

							if (this.player.videoWidth() === this.player.videoHeight()) {
								this.square = true;
							}
						});
					});
					this.player.on('error', () => {
						this.video = false;
						this.visible = false;
					});
				},
				() => {
					this.visible = false;
				}
			);
		},
		methods: {
			doCountdown() {
				if (this.interval) {
					this.countdown -= 1;
					if (this.countdown === 0) {
						window.clearInterval(this.interval);
					}
				}
			},
			hideVideo() {
				this.visible = false;
			},
		},
	};
</script>
