<template>
	<div>
		<h1>Exhibition Area</h1>
		<p>The content you are trying to access is only available to {{ platformSettings.siteName }} participants</p>
		<div class="buttons">
			<a class="button is-primary" :href="authUrl">Login</a>
			<a class="button is-primary is-outlined" :href="registerUrl" @click="cancel()">Register</a>
		</div>
	</div>
</template>

<script>
	import { AUTHURL, REGISTERURL } from '../consts';
	import platformSettings from '../utils/platformSettings';

	export default {
		name: 'LoginModal',
		props: {
			to: Object,
		},
		data() {
			return {
				registerUrl: REGISTERURL,
				authUrl: AUTHURL,
				platformSettings: platformSettings.state,
			};
		},
		methods: {
			cancel() {
				this.$emit('close');
			},
		},
	};
</script>
