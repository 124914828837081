<template>
	<div v-if="sessionList.length > 0" class="agenda__upcoming__wrapper">
		<div class="agenda__external">
			<div class="container">
				<div v-for="event in sessionList" class="external-session">
					<a v-if="event.external_url && event.banner" :href="event.external_url" target="_blank"><img :src="event.banner | imageUrl"/></a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import $http from '../utils/http.js';
	import session from '../utils/session';
	import moment from 'moment-timezone';
	import platformSettings from '../utils/platformSettings';

	export default {
		name: 'ExternalSessions',
		data() {
			return {
				programme: {},
				dates: [],
				loading: true,
				sessionList: [],
				currentDate: null,
				currentList: false,
				session: session.state,
				platformSettings: platformSettings.state,
			};
		},
		computed: {
			allCount() {
				if (!this.programme && !this.currentDate) return false;
				if (!this.programme[this.currentDate]) return false;
				return this.programme[this.currentDate].length;
			},
			favCount() {
				if (!this.programme && !this.currentDate) return false;
				if (!this.programme[this.currentDate]) return false;
				return this.programme[this.currentDate].filter(e => e.favourite).length;
			},
			isLogged() {
				return this.session.loggedIn;
			},
			backgroundUrl() {
				return this.background && this.background.url ? `url('${this.$options.filters.imageUrl(this.background)}')` : null;
			},
		},
		mounted() {
			this.loading = true;
			$http.get(`/event-programme`).then(data => {
				this.setData(data);
			});
		},
		methods: {
			setData(data) {
				let sessions = data.data.filter(e => {
					return e.external === true && ((e.private === true && e.user_allowed === true) || e.private === false);
				});

				sessions = sessions.reduce(function(r, a) {
					r[a.date] = r[a.date] || [];
					r[a.date].push(a);
					return r;
				}, Object.create(null));
				this.dates = Object.keys(sessions).sort((a, b) => (new Date(a) > new Date(b) ? 1 : new Date(a) < new Date(b) ? -1 : 0));
				let now = moment().tz('CET');
				if (this.dates.length > 0) {
					this.dates = this.dates.filter(e => {
						return now.isSame(moment(e, 'YYYY-MM-DD', 'CET'), 'day');
					});
				}

				this.currentDate = this.dates.length > 0 ? this.dates[0] : null;

				if (this.currentDate) {
					sessions = sessions[this.currentDate];
					sessions.sort((a, b) => (a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0));
					sessions.sort((a, b) =>
						new Date(`${a.date} ${a.start}`) > new Date(`${b.date} ${b.start}`)
							? 1
							: new Date(`${a.date} ${a.start}`) < new Date(`${b.date} ${b.start}`)
							? -1
							: 0
					);
					sessions = sessions.filter(e => this.isVisible(e));
				}

				if (sessions.length > 0) {
					this.sessionList = sessions;
					this.sessionList.map(e => {
						if (e.speakers) {
							e.speakers = e.speakers.sort((a, b) => (a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0));
						}
						return e;
					});
				}

				this.loading = false;
			},
			toggleFavourite(session) {
				$http.put(`/event-programme-favourite/${session.id}`).then(
					() => {
						session.favourite = !session.favourite;
					},
					() => {}
				);
			},
			selectList(fav) {
				if (fav && !this.isLogged) return;
				this.currentList = fav;
				this.sessionList = fav ? this.programme[this.currentDate].filter(e => e.favourite) : this.programme[this.currentDate];
				this.sessionList.map(e => {
					if (e.speakers) {
						e.speakers = e.speakers.sort((a, b) => (a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0));
					}
					return e;
				});
			},
			isLive({ date, start, end }) {
				let target = moment.tz.guess();
				let dStart = moment.tz(`${date} ${start}`, 'CET');
				let dEnd = moment.tz(`${date} ${end}`, 'CET');
				let now = moment().tz(target);

				return now.isBetween(dStart, dEnd);
			},
			isVisible({ date, end }) {
				let target = moment.tz.guess();
				let dEnd = moment.tz(`${date} ${end}`, 'CET');
				let now = moment().tz(target);

				return now.isBefore(dEnd);
			},
		},
	};
</script>
