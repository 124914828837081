<template>
	<div class="article">
		<h2 class="is-title is-inverted">{{ title }}</h2>
		<div class="article-background" :style="{ backgroundImage: background }"></div>
		<div class="privacy-policy">
			<VueMarkdown v-if="content" :source="content" class="article-content content" />
		</div>
	</div>
</template>

<script>
	import $http from '../utils/http.js';
	import VueMarkdown from 'vue-markdown-v2';
	import meta from "../utils/meta.js";

	export default {
		name: 'PrivacyPolicy',
		components: { VueMarkdown },
		data() {
			return {
				title: null,
				content: null,
				background: null,
			};
		},
		beforeRouteEnter(to, from, next) {
			let url = `/privacy-policy`;
			if (to.meta.type === 'embargo') {
				url = `/embargo-policy`;
			}
			$http.get(url).then(data => {
				next(vm => vm.setData(data.data));
			});
		},
		beforeRouteUpdate(to, from, next) {
			let url = `/privacy-policy`;
			if (to.meta.type === 'embargo') {
				url = `/embargo-policy`;
			}
			$http.get(url).then(data => {
				this.setData(data.data);
				next();
			});
		},
		methods: {
			setData(data) {
				this.title = data.title;
				this.content = data.content;
				this.background = data.background && data.background.url ? `url('${this.$options.filters.imageUrl(data.background)}')` : null;
				meta.setTitle(this.title);
			},
		},
	};
</script>
