<template>
	<div>
		<h1>Login failed</h1>
		<p>Your user account doesn't have the access rights for this website.</p>
		<div class="buttons">
			<a class="button is-primary" @click="cancel()">OK</a>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'LoginFailedModal',
		props: {
			to: Object,
		},
		data() {
			return {};
		},
		methods: {
			cancel() {
				this.$emit('close');
			},
		},
	};
</script>
