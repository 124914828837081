<template>
	<Notification
		v-if="isVisible"
		:data="data"
		:class="{ 'is-disappearing': isDisappearing }"
		toast
		@mouseover.native="pauseTimer"
		@mouseleave.native="resumeTimer"
		@notification:hide="hide"
	>
		<progress class="progress" :value="time" :max="hideAfter * fps" />
	</Notification>
</template>

<script>
	import Notification from './Notification';

	export default {
		name: 'NotificationToast',

		components: {
			Notification,
		},

		props: {
			data: Object,
		},

		data() {
			return {
				elementVisible: true,
				fps: 60,
				hideAfter: 12,
				interval: null,
				isDisappearing: false,
				isVisible: true,
				time: 0,
			};
		},

		beforeMount() {
			const closedNotification = JSON.parse(localStorage.getItem('closedNotification')) || [];

			closedNotification.forEach(id => {
				if (id === this.data.id) {
					this.isVisible = false;
					clearInterval(this.interval);
				}
			});
		},

		mounted() {
			this.setTimer();
		},

		beforeDestroy() {
			clearInterval(this.interval);
		},

		methods: {
			hide() {
				clearInterval(this.interval);
				this.isDisappearing = true;

				setTimeout(() => {
					this.isDisappearing = false;
					this.isVisible = false;
				}, 500);

				const closedNotification = JSON.parse(localStorage.getItem('closedNotification')) || [];
				closedNotification.push(this.data.id);
				localStorage.setItem('closedNotification', JSON.stringify(closedNotification));
			},

			pauseTimer() {
				clearInterval(this.interval);
			},

			resumeTimer() {
				this.setTimer();
			},

			setTimer() {
				this.interval = setInterval(() => {
					if (this.time === this.hideAfter * this.fps) {
						this.hide();
					}
					this.time += 1;
				}, 1000 / this.fps);
			},
		},
	};
</script>
