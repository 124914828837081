import axios from 'axios';
import { APIURL } from '../consts';
import session from './session';

const storeKey = 'dcwsession';
const storeUser = 'dcwuser';

axios.defaults.baseURL = APIURL;

axios.interceptors.request.use(config => {
	if (session.state.loggedIn === true && session.state.key) {
		config.headers = { Authorization: `Bearer ${session.state.key}` };
	} else {
		if (window.localStorage.getItem(storeKey)) {
			let jwt = window.localStorage.getItem(storeKey);
			let user = window.localStorage.getItem(storeUser);
			if (user) {
				user = JSON.parse(user);
			}
			if (jwt && user) {
				session.state.loggedIn = true;
				session.state.key = jwt;
				session.state.user = user;
				config.headers = { Authorization: `Bearer ${jwt}` };
			}
		}
	}
	return config;
});

axios.interceptors.response.use(
	response => response,
	error => {
		return new Promise(function(resolve, reject) {
			if (error.config && error.response && error.response.status === 401 && !error.config.__isRetry) {
				error.config.__isRetry = true;
				session.logout();
				delete error.config.headers.Authorization;
				axios(error.config).then(resolve, reject);
			} else throw error;
		});
	}
);

export default axios;
