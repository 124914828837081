<template>
	<div class="agenda-session" :class="[`is-${view}`, { 'is-large': isLarge, 'is-break': !session.visitable, 'is-striped': session.sponsored }]">
		<div class="agenda-session__content">
			<div class="agenda-session__main">
				<div class="agenda-session__time">
					<div>
						<p v-if="isSpeaker">
							<span>{{ session.date | dayFormat }}</span>
						</p>
						<p>
							<strong v-if="isLive(session) && session.visitable === true" class="agenda-session__livenow">Live</strong>
							<span>{{ session.start | timeFormat(session.date) }} - {{ session.end | timeFormat(session.date) }}</span> CET
						</p>
						<small v-if="isLarge"
							><span>{{ session.start | localTimeDateFormat(session.date) }} - {{ session.end | localTimeFormat(session.date) }}</span> Your local time</small
						>
					</div>
					<div v-if="isLarge && session.live_track && session.live_track.title">
						<span class="tag-track">{{ session.live_track.title }}</span>
					</div>
					<div v-if="isLarge && session.session_hall && session.session_hall.name">
						<span class="tag-track">{{ session.session_hall.name }}</span>
					</div>
				</div>
				<h2 v-if="altTitle && session.title_regulated" :style="[session.colour && { 'border-left-color': session.colour }]" :class="{ 'is-presentation': isPresentation }">
					<router-link v-if="isLarge && session.visitable === true" :to="{ name: 'AgendaSession', params: { url: session.url } }" @click.native="eventClickTitle">{{
						session.title_regulated
					}}</router-link>
					<span v-else>{{ session.title_regulated }}</span>
				</h2>
				<h2 v-else :style="[session.colour && { 'border-left-color': session.colour }]" :class="{ 'is-presentation': isPresentation }">
					<router-link v-if="isLarge && session.visitable === true" :to="{ name: 'AgendaSession', params: { url: session.url } }" @click.native="eventClickTitle">{{
						session.title
					}}</router-link>
					<span v-else>{{ session.title }}</span>
				</h2>

				<h3 v-if="session.session_type && session.session_type.name && !isPresentation">{{ session.session_type.name }}</h3>

				<router-link
					v-if="isLarge && session.external !== true && isLogged && session.live_track && session.live_track.url && isLive(session)"
					:to="{ name: 'LiveTrack', params: { url: session.live_track.url } }"
					class="agenda__status is-small is-live"
					@click.native="eventClickJoin"
				>
					<span class="icon"><inline-svg :src="require('../assets/video-call.svg')" width="25"></inline-svg></span>
					<span>Join session</span>
				</router-link>
				<a
					v-if="isLarge && session.external === true && session.user_allowed === true && isLogged && isLiveExternal(session)"
					class="agenda__status is-small is-live"
					:href="session.external_url"
					target="_blank"
				>
					<span class="icon"><inline-svg :src="require('../assets/video-call.svg')" width="25"></inline-svg></span>
					<span>Join session</span>
				</a>
				<div v-if="isLarge && session.short_description" class="agenda-session__short-description">
					<p>{{ session.short_description }}</p>
				</div>
				<div v-if="isLarge && !isSpeaker && session.speakers && session.speakers.length > 0" class="agenda-session__speakers">
					<router-link
						v-for="speaker in session.speakers"
						:key="speaker.id"
						:to="{ name: 'AgendaSpeaker', params: { url: speaker.url } }"
						class="agenda-session__speaker"
						@click.native="eventClick"
					>
						<div class="image">
							<img v-if="speaker.profile_picture" :src="speaker.profile_picture | imageUrl('small')" />
							<img v-else src="@/assets/placeholder_speaker.jpg" />
						</div>
						<div>
							<h3>{{ speaker.firstName }} {{ speaker.lastName }}</h3>
							<h4>{{ speaker.country }}</h4>
						</div>
					</router-link>
				</div>
				<div v-if="!isLarge && isPresentation && session.speakers && session.speakers.length > 0" class="agenda-session__speakers">
					<router-link
						v-for="speaker in session.speakers"
						:key="speaker.id"
						:to="{ name: 'AgendaSpeaker', params: { url: speaker.url } }"
						class="agenda-session__speaker"
						@click.native="eventClick"
					>
						<img v-if="speaker.profile_picture" :src="speaker.profile_picture | imageUrl('thumbnail')" :title="`${speaker.firstName} ${speaker.lastName}`" />
						<img v-else src="@/assets/placeholder_speaker.jpg" :title="`${speaker.firstName} ${speaker.lastName}`" />
					</router-link>
				</div>
				<div v-if="!isLarge && !isPresentation && session.speakers && session.speakers.length > 0" class="agenda-session__speakers">
					<div v-for="speaker in session.speakers" :key="speaker.id">
						<img v-if="speaker.profile_picture" :src="speaker.profile_picture | imageUrl('thumbnail')" :title="`${speaker.firstName} ${speaker.lastName}`" />
						<img v-else src="@/assets/placeholder_speaker.jpg" :title="`${speaker.firstName} ${speaker.lastName}`" />
					</div>
				</div>
				<div v-if="isLarge && isLogged && session.visitable" class="agenda__favourite" :class="{ 'is-favourited': session.favourite }">
					<a :title="session.favourite ? 'Remove from My Agenda' : 'Add to My Agenda'" @click.prevent.stop="toggleFavourite(session)">
						<inline-svg :src="require('../assets/calendar-plus.svg')" width="26"></inline-svg>
					</a>
				</div>
				<div v-if="displayStarred && session.starred" class="star"><inline-svg :src="require('../assets/star.svg')" width="25"></inline-svg></div>
			</div>
			<div v-if="isLarge && session.session_logo" class="agenda-session__logo">
				<img :src="session.session_logo | imageUrl" />
			</div>
		</div>
		<div v-if="!isLarge && isLogged && session.visitable" class="agenda__favourite" :class="{ 'is-favourited': session.favourite }">
			<a class="has-tooltip-left" :data-tooltip="session.favourite ? 'Remove from My Agenda' : 'Add to My Agenda'" @click.prevent.stop="toggleFavourite(session)">
				<inline-svg :src="require('../assets/calendar-plus.svg')" width="26"></inline-svg>
			</a>
		</div>
	</div>
</template>

<script>
	import moment from 'moment-timezone';
	import session from '../utils/session';
	import $http from '../utils/http';

	export default {
		name: 'AgendaSessionBox',

		props: {
			session: Object,
			view: String,
			altTitle: Boolean,
			starred: Boolean,
			popup: Boolean,
		},
		data() {
			return {
				userSession: session.state,
			};
		},
		computed: {
			isLogged() {
				return this.userSession.loggedIn;
			},
			isLarge() {
				return this.view === 'large' || this.view === 'speaker';
			},
			isSpeaker() {
				return this.view === 'speaker';
			},
			isPresentation() {
				return this.view === 'presentation';
			},
			displayStarred() {
				return this.starred !== false;
			},
		},
		methods: {
			isLive({ date, start, end }) {
				let target = moment.tz.guess();
				let dStart = moment.tz(`${date} ${start}`, 'CET');
				let dEnd = moment.tz(`${date} ${end}`, 'CET');
				let now = moment().tz(target);

				return now.isBetween(dStart, dEnd);
			},
			isLiveExternal({ date, start, end }) {
				let target = moment.tz.guess();
				let dStart = moment.tz(`${date} ${start}`, 'CET').subtract(15, 'minutes');
				let dEnd = moment.tz(`${date} ${end}`, 'CET');
				let now = moment().tz(target);

				return now.isBetween(dStart, dEnd);
			},
			toggleFavourite(session) {
				session.favourite = !session.favourite;
				$http.put(`/event-programme-favourite/${session.id}`).then(
					() => {},
					() => {
						session.favourite = !session.favourite;
					}
				);
			},
			eventClick() {
				this.$emit('clicked');
			},
			eventClickTitle() {
				if (this.view === 'large' && !this.popup) {
					this.$gtm.dataLayer().push({
						event: 'gaEvent',
						eCategory: 'Programme Agenda Event',
						eAction: this.session.session_category ? this.session.session_category.title + ' - List' : 'List',
						eLabel: this.session.title,
						Exhibitor: this.session.session_ga ? this.session.session_ga : '(not set)',
						Booth: '(not set)',
					});
				}
				this.$emit('clicked');
			},
			eventClickJoin() {
				this.$gtm.dataLayer().push({
					event: 'gaEvent',
					eCategory: 'Programme Agenda Event',
					eAction: this.session.session_category ? this.session.session_category.title + ' - Join Session' : 'Join Session',
					eLabel: this.session.title,
					Exhibitor: this.session.session_ga ? this.session.session_ga : '(not set)',
					Booth: '(not set)',
				});
				this.$emit('clicked');
			},
		},
	};
</script>

<style scoped></style>
