import http from './http.js';
import meta from './meta';

const platformSettings = {
	state: {
		gamification_enabled: false,
		gamification_inprogress: false,
		siteName: 'EAACI',
		boothLiveChat: false,
		boothBusinessCard: false,
		boothReview: false,
		liveNow: false,
		congressTimeBox: false,
		closureCountdown: false,
		closureDate: null,
		siteTour: false,
		exhibitionExchange: false,
		logoGraphic: null,
		logoText: null,
		footerLogoGraphic: null,
		footerLogoText: null,
		upcomingSessions: false,
		platformSettingsLoaded: false,
		certificatesDownloadEnabled: false,
		congressEnd: null,
		congressStart: null,
		loading: true,
		profileFrameEnabled: false,
		profileFrameImage: null,
		chat: false,
		matchmakingEnabled: false,
		recommendationsEnabled: false,
	},
	async loadData() {
		if (this.state.platformSettingsLoaded === true) {
			return true;
		} else {
			try {
				const { data } = await http.get('/platform-settings');
				this.state.gamification_enabled = data.gamification_enabled;
				this.state.gamification_inprogress = data['gamification_in-progress'];
				this.state.siteName = data.site_name ? data.site_name : 'EAACI';
				meta.setTitle(null);
				this.state.boothLiveChat = data.booths_live_chat;
				this.state.boothBusinessCard = data.booths_business_card_exchange;
				this.state.boothReview = data.booths_rating;
				this.state.liveNow = data.feature_live_now;
				this.state.congressTimeBox = data.feature_congress_time;
				this.state.closureCountdown = data.feature_platform_closure;
				this.state.closureDate = data.platform_closure;
				this.state.siteTour = data.feature_site_tour;
				this.state.exhibitionExchange = data.feature_exhibition_open;
				this.state.logoGraphic = data.event_logo_graphic;
				this.state.logoText = data.event_logo_text;
				this.state.footerLogoGraphic = data.event_footer_logo;
				this.state.footerLogoText = data.event_footer_text;
				this.state.upcomingSessions = data.feature_upcoming_sessions;
				this.state.certificatesDownloadEnabled = data.certificates_download_enabled;
				this.state.congressEnd = data.congress_end;
				this.state.congressStart = data.congress_start;
				this.state.platformSettingsLoaded = true;
				this.state.profileFrameEnabled = data.profile_frame_enabled;
				this.state.profileFrameImage = data.profile_frame;
				this.state.chat = data.feature_chat;
				this.state.matchmakingEnabled = data.oraculum_enabled;
				this.state.recommendationsEnabled = data.recommendations_enabled;
				return true;
			} catch (err) {
				return false;
			}
		}
	},
	loader(value) {
		this.state.loading = value;
	},
};

export default platformSettings;
