<template>
	<div class="sponsors-wrapper">
		<div v-if="sponsors && sponsors.length > 0" class="sponsors">
			<h2 class="is-title">{{ title }}</h2>
			<VueMarkdown v-if="subtitle" :source="subtitle" class="has-text-centered" />
			<div class="sponsors-items">
				<div v-for="fs in sponsors" :key="fs.id" class="sponsors-item">
					<Link v-if="fs.link && fs.link.url" :link="fs.link">
						<img v-if="fs.logo && fs.logo.url" :src="fs.logo | imageUrl" :alt="fs.name" />
						<span v-else>{{ fs.name }}</span>
					</Link>
					<span v-else>
						<img v-if="fs.logo && fs.logo.url" :src="fs.logo | imageUrl" :alt="fs.name" />
						<span v-else>{{ fs.name }}</span>
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Link from './Link';
	import VueMarkdown from "vue-markdown-v2";
	export default {
		name: 'Sponsors',
		components: { Link, VueMarkdown },
		props: {
			sponsors: Array,
			title: String,
			subtitle: String,
		},
	};
</script>

<style scoped></style>
