<template>
	<div>
		<AgendaSessionBox :session="session" view="large" :alt-title="altTitle" :starred="starred" :popup="true" @clicked="onClick" />
		<span class="close" @click="cancel">&times;</span>
	</div>
</template>

<script>
	import AgendaSessionBox from './AgendaSessionBox';
	export default {
		name: 'AgendaSessionModal',
		components: { AgendaSessionBox },
		props: {
			session: Object,
			altTitle: Boolean,
			starred: Boolean,
		},
		data() {
			return {};
		},
		methods: {
			cancel() {
				this.$emit('close');
			},
			onClick() {
				this.$gtm.dataLayer().push({
					event: 'gaEvent',
					eCategory: 'Programme Agenda Event',
					eAction: this.session.session_category ? this.session.session_category.title + ' - Popup Click' : 'Popup Click',
					eLabel: this.session.title,
					Exhibitor: this.session.session_ga ? this.session.session_ga : '(not set)',
					Booth: '(not set)',
				});
				this.$emit('close');
			},
		},
	};
</script>
