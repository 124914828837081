export default function linkRoute(type, url) {
	switch (type) {
		case 'article':
			return { name: 'Article', params: { url: url } };
		case 'homepage':
			return { name: 'Homepage' };
		case 'exhibition':
			return { name: 'Exhibition' };
		case 'nasvillage':
			return { name: 'NasVillage' };
		case 'booth':
			return { name: 'Booth', params: { url: url } };
		case 'industryprogramme':
			return { name: 'IndustryProgramme' };
		default:
			return {};
	}
}
