import headful from 'headful';
import platformSettings from './platformSettings';

const meta = {
	setTitle(text) {
		if (text) {
			headful({ title: `${text} | ${platformSettings.state.siteName}` });
		} else {
			headful({ title: platformSettings.state.siteName });
		}
	},
};

export default meta;
