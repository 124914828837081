import { ENV, MEDIAURL, MEDIAURLCDN } from '../consts';

export const prerender = data => {
	if (ENV === 'production') {
		let regExp = new RegExp(MEDIAURL + '/', 'g');
		return data.replace(regExp, MEDIAURLCDN + '/');
	} else {
		return data;
	}
};
