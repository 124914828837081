<template>
	<div class="article">
		<h2 class="is-title is-inverted">{{ title }}</h2>
		<div class="article-background" :style="{ backgroundImage: background }"></div>
		<VueMarkdown v-if="description" :source="description" class="article-description" />
		<div class="article-content-wrapper" :class="{ 'has-description': !!description }">
			<aside v-if="leftMenu" class="article-aside">
				<h3>Info Menu</h3>
				<ul class="article-submenu">
					<li v-for="item in sideMenu" :key="item.url" :class="{ 'is-active': item.url === $route.params.url }">
						<router-link :to="{ name: 'Article', params: { url: item.url } }" class="article-submenu__item-inner"
							><span class="article-submenu__item-text">{{ item.name }}</span></router-link
						>
					</li>
				</ul>
			</aside>
			<VueMarkdown
				v-if="content"
				ref="article"
				:source="content"
				class="article-content content"
				:class="{ 'has-menu-left': leftMenu }"
				:prerender="prerender"
				@rendered="rendered"
			/>
		</div>
	</div>
</template>

<script>
	import $http from '../utils/http.js';
	import VueMarkdown from 'vue-markdown-v2';
	import session from '../utils/session';
	import videojs from 'video.js';
	import { prerender } from '../utils/markdown';
	import meta from '../utils/meta.js';

	export default {
		name: 'Article',
		components: { VueMarkdown },
		data() {
			return {
				title: null,
				description: null,
				content: null,
				background: null,
				sideMenu: [],
				leftMenu: false,
			};
		},
		beforeRouteEnter(to, from, next) {
			$http.get(`/articles?url=${to.params.url}`).then(data => {
				next(vm => vm.setData(data));
			});
		},
		beforeRouteUpdate(to, from, next) {
			if (window.tidioKey) {
				window.sessionStorage.setItem('forceReload', JSON.stringify({ name: to.name, params: to.params }));
				location.reload();
			} else {
				$http.get(`/articles?url=${to.params.url}`).then(data => {
					this.setData(data);
					next();
				});
			}
		},
		beforeRouteLeave(to, from, next) {
			if (window.tidioKey) {
				window.sessionStorage.setItem('forceReload', JSON.stringify({ name: to.name, params: to.params }));
				location.reload();
			} else {
				next();
			}
		},
		beforeDestroy() {
			document.removeEventListener('tidioChat-ready', this.tidioInit);
		},
		mounted() {
			if (window.tidioChatApi) {
				window.tidioChatApi.on('ready', this.tidioInit);
			} else {
				document.addEventListener('tidioChat-ready', this.tidioInit);
			}
		},
		methods: {
			setData(data) {
				if (data.data.length > 0) {
					let article = data.data[0];
					this.title = article.name;
					this.description = article.description;
					this.content = article.content;
					this.background = article.background && article.background.url ? `url('${this.$options.filters.imageUrl(article.background)}')` : null;
					if (article.type === 'infopage') {
						this.leftMenu = true;
						this.loadInfomenu();
					}
					meta.setTitle(this.title);

					if (article.liveChat && article.liveChat.length === 32) {
						if (!window.tidioKey) {
							if (session.state.user && typeof session.state.user.kitId !== 'undefined') {
								document.tidioIdentify = {
									distinct_id: '' + session.state.user.kitId,
									email: session.state.user.email,
									username: `${session.state.user.firstName} ${session.state.user.lastName}`,
								};
							}
							window.tidioKey = article.liveChat;
							let tidioScript = document.createElement('script');
							tidioScript.src = `//code.tidio.co/${article.liveChat}.js`;
							document.body.appendChild(tidioScript);
						} else if (window.tidioKey && window.tidioKey !== article.liveChat) {
							location.reload();
						}
					}
				}
			},
			loadInfomenu() {
				$http.get('/articles?type=infopage').then(data => {
					this.sideMenu = data.data.map(({ name, url }) => ({ name, url }));
				});
			},
			tidioInit() {
				window.tidioChatApi.setContactProperties({
					prescriber: session.state.user.prescriber ? 'Yes' : 'No',
					pharma_company: session.state.user.pharmaEmployee ? 'Yes' : 'No',
					profession: session.state.user.user_profession ? session.state.user.user_profession.name : '',
					country: session.state.user.user_country ? session.state.user.user_country.name : '',
				});
			},
			prerender: prerender,
			rendered() {
				this.$nextTick(() => {
					if (this.$refs.article) {
						let vids = this.$refs.article.$el.getElementsByTagName('video');
						if (vids) {
							for (let v of vids) {
								videojs(v);
							}
						}
					}
				});
			},
		},
	};
</script>
