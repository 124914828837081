<template>
	<div>
		<Header :menu="menu" :info="infoMenu" />
		<router-view />
		<BannerRegister v-if="bannerRegister && bannerRegister.active" :data="bannerRegister" />
		<Footer />
		<vue-progress-bar />
		<VideoAds v-if="videoAds" />
		<CookieInfo />
		<div v-if="loading" class="loader-box">
			<div class="loader-circle"></div>
			<div class="loader-line-mask"><div class="loader-line"></div></div>
			<img src="/loader_logo.png" :alt="platformSettings.siteName" />
		</div>
		<v-tour v-if="platformSettings.siteTour" name="dcwTour" :steps="tourSteps" :options="{ highlight: true }" :callbacks="tourCallbacks"></v-tour>
		<div v-if="platformSettings.siteTour && $tours && $tours['dcwTour'] && $tours['dcwTour'].isRunning" class="tidio-placeholder"></div>
    <modals-container></modals-container>
	</div>
</template>

<script>
	import Header from './components/Header';
	import BannerRegister from './components/BannerRegister';
	import Footer from './components/Footer';
	import $http from './utils/http.js';
	import session from './utils/session';
	import VideoAds from './components/VideoAds';
	import CookieInfo from './components/CookieInfo';
	import { ENV } from './consts';
	import platformSettings from './utils/platformSettings';
	import ProfileCheckModal from './components/ProfileCheckModal';

	const tourData = {
		development: ["li[data-menu-item='9']", "li[data-menu-item='11']", "li[data-menu-info='2']", 'ipsum-booth'],
		production: ["li[data-menu-item='5']", "li[data-menu-item='14']", "li[data-menu-item='13']", 'eaaci-campus'],
		staging: ["li[data-menu-item='13']", "li[data-menu-item='16']", "li[data-menu-info='17']", 'test-booth'],
	};

	export default {
		components: { CookieInfo, VideoAds, Footer, BannerRegister, Header },
		data() {
			return {
				menu: [],
				infoMenu: [],
				bannerRegister: null,
				tourSteps: [
					{
						target: tourData[ENV][0],
						content: `Click here to explore our Live Scientific Programme`,
						params: {
							enableScrolling: false,
						},
					},
					{
						target: tourData[ENV][1],
						content: `E-Meet our Exhibitors `,
						params: {
							enableScrolling: false,
						},
					},
					{
						target: '.activity-challenge',
						content: 'Learn how to participate in the EAACI 2020 Activity Challenge.',
						params: {
							enableScrolling: false,
						},
					},
					{
						target: tourData[ENV][2],
						content: `Get the fastest and most effective support by clicking on the Live Chat feature in the Virtual EAACI Campus `,
						params: {
							enableScrolling: false,
						},
					},
					{
						target: '.tidio-placeholder',
						content: 'Communicate and collaborate with our industry sponsors via the Live chat feature in each exhibitor booth',
					},
				],
				tourCallbacks: {
					onStart: this.tourStart,
					onNextStep: this.tourNextStep,
					onPreviousStep: this.tourPreviousStep,
					onFinish: this.tourFinish,
					onSkip: this.tourSkip,
				},
				platformSettings: platformSettings.state,
				session: session.state,
			};
		},
		computed: {
			videoAds() {
				return this.$route.name === 'Exhibition' || this.$route.name === 'IndustryProgramme';
			},
			headerLogoGraphic() {
				if (this.platformSettings.logoGraphic) {
					return this.$options.filters.imageUrl(this.platformSettings.logoGraphic);
				}
				return require('./assets/logo.png');
			},
			loading() {
				return this.platformSettings.loading;
			},
		},
		watch: {
			'session.loggedIn': 'loginWatcher',
		},
		mounted() {
			$http.get('/navigations?_sort=sort:ASC').then(data => {
				this.menu = data.data
					.filter(e => e.parent === null)
					.map(e => {
						e.children = data.data.filter(x => x.parent && x.parent.id === e.id);
						return e;
					});
				this.$Progress.finish();
				if (session.state.loggedIn === true && this.platformSettings.siteTour === true) {
					let storeInfo = window.localStorage.getItem('tourHidden');
					if (!storeInfo) {
						if (window.innerWidth > 1023) {
							window.setTimeout(() => {
								this.$tours['dcwTour'].start();
							}, 4000);
						}
					}
				}
			});

			$http.get('/articles?type=infopage').then(data => {
				this.infoMenu = data.data.map(({ name, url, id }) => ({ name, url, id }));
			});

			$http.get('/banner-register-now').then(
				data => {
					this.bannerRegister = data.data;
				},
				() => {}
			);

			if (session.state.loggedIn === true && typeof session.state.user.public_profile !== 'boolean') {
				$http.get(`/user-info-light`).then(({ data }) => {
					session.updateProfile(data.public_profile);
				});
			}

			let reloaded = window.sessionStorage.getItem('forceReload');
			if (reloaded) {
				window.sessionStorage.removeItem('forceReload');
				reloaded = JSON.parse(reloaded);
				this.$router.push({ name: reloaded.name, params: reloaded.params });
			}
		},
		created() {
			// "quickfix"
			this.$nextTick(() => {
				this.$gtm.trackEvent({
					event: 'data',
					userId: session.state.user && session.state.user.kitId ? session.state.user.kitId : undefined,
					Exhibitor: '(not set)',
					Booth: '(not set)',
				});
			});

			var query = window.location.search.substring(1);
			if (query.length && query.startsWith('error=access_denied')) {
				if (window.history != undefined && window.history.pushState != undefined) {
					window.history.pushState({}, document.title, window.location.pathname);
				}
			}

			this.loginWatcher();
		},
		methods: {
			tourStart() {
				const el = document.querySelector(this.tourSteps[0].target);
				if (el) el.parentElement.parentElement.classList.add('hover');
			},
			tourPreviousStep(currentStep) {
				if (currentStep === 1) {
					const el = document.querySelector(this.tourSteps[0].target);
					const nextEl = document.querySelector(this.tourSteps[1].target);
					if (el) el.parentElement.parentElement.classList.add('hover');
					if (nextEl) nextEl.parentElement.parentElement.classList.remove('hover');
				} else if (currentStep === 2) {
					const el = document.querySelector(this.tourSteps[1].target);
					if (el) el.parentElement.parentElement.classList.add('hover');
				} else if (currentStep === 3) {
					const nextEl = document.querySelector(this.tourSteps[3].target);
					if (nextEl) nextEl.parentElement.parentElement.classList.remove('hover');
				} else if (currentStep === 4) {
					const el = document.querySelector(this.tourSteps[3].target);
					if (el) el.parentElement.parentElement.classList.add('hover');
				}
			},
			tourNextStep(currentStep) {
				if (currentStep === 0) {
					const el = document.querySelector(this.tourSteps[0].target);
					const nextEl = document.querySelector(this.tourSteps[1].target);
					if (el) el.parentElement.parentElement.classList.remove('hover');
					if (nextEl) nextEl.parentElement.parentElement.classList.add('hover');
				} else if (currentStep === 1) {
					const el = document.querySelector(this.tourSteps[1].target);
					if (el) el.parentElement.parentElement.classList.remove('hover');
					if (this.$route.name !== 'Booth' || (this.$route.name === 'Booth' && this.$route.params.url !== 'ipsum-booth')) {
						this.$router.push({ name: 'Booth', params: { url: tourData[ENV][3] } });
					}
				} else if (currentStep === 2) {
					const nextEl = document.querySelector(this.tourSteps[3].target);
					if (nextEl) nextEl.parentElement.parentElement.classList.add('hover');
				} else if (currentStep === 3) {
					const el = document.querySelector(this.tourSteps[3].target);
					if (el) el.parentElement.parentElement.classList.remove('hover');
				}
			},
			tourFinish() {
				const el0 = document.querySelector(this.tourSteps[0].target);
				const el1 = document.querySelector(this.tourSteps[1].target);
				const el2 = document.querySelector(this.tourSteps[3].target);
				if (el0) el0.parentElement.parentElement.classList.remove('hover');
				if (el1) el1.parentElement.parentElement.classList.remove('hover');
				if (el2) el2.parentElement.parentElement.classList.remove('hover');
				window.localStorage.setItem('tourHidden', '1');
				$http.post('/create-action', {
					category_type: 'sequential_tooltips',
				});
			},
			tourSkip() {
				const el0 = document.querySelector(this.tourSteps[0].target);
				const el1 = document.querySelector(this.tourSteps[1].target);
				const el2 = document.querySelector(this.tourSteps[3].target);
				if (el0) el0.parentElement.parentElement.classList.remove('hover');
				if (el1) el1.parentElement.parentElement.classList.remove('hover');
				if (el2) el2.parentElement.parentElement.classList.remove('hover');
				window.localStorage.setItem('tourHidden', '1');
			},
			loginWatcher() {
				this.$router.onReady(() => {
					if (this.session.loggedIn === true && this.session.user && !this.session.user.profileChecked && this.$router.currentRoute.name !== 'EditProfile') {
						this.$modal.show(
							ProfileCheckModal,
							{},
							{
								classes: 'user-check-modal',
								width: '90%',
								maxWidth: 840,
								height: 'auto',
								adaptive: true,
								clickToClose: false,
							},
							{}
						);
					}
				});
			},
		},
	};
</script>
