import Vue from 'vue';
import VueRouter from 'vue-router';
import Homepage from '../views/Homepage.vue';
import Article from '../views/Article.vue';
import Booth from '../views/Booth.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import Auth from '../views/Auth.vue';
import platformSettings from '../utils/platformSettings';
import { AUTHURL, RESTRICTED_PLATFORM } from '../consts';
import session from '../utils/session';
import $http from '../utils/http';
import LoginModal from '../components/LoginModal';
import ExhibitionOff from '../components/ExhibitionOff';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Homepage',
		component: Homepage,
	},
	{
		path: '/article/:url',
		name: 'Article',
		component: Article,
	},
	{
		path: '/exhibition',
		name: 'Exhibition',
		meta: { type: 'exhibition' },
		component: () => import(/* webpackChunkName: "about" */ '../views/Exhibition.vue'),
	},
	{
		path: '/nas-village',
		name: 'NasVillage',
		meta: { type: 'nas' },
		component: () => import(/* webpackChunkName: "about" */ '../views/Exhibition.vue'),
	},
	{
		path: '/exhibition/:url/:article?',
		name: 'Booth',
		component: Booth,
	},
	{
		path: '/industry-programme',
		name: 'IndustryProgramme',
		component: () => import(/* webpackChunkName: "IndustryProgramme" */ '../views/IndustryProgramme.vue'),
	},
	{
		path: '/activity-challenge',
		name: 'ActivityChallenge',
		component: () => import(/* webpackChunkName: "ActivityChallenge" */ '../views/ActivityChallenge.vue'),
	},
	{
		path: '/activity-challenge/:code',
		name: 'ActivityCode',
		component: () => import(/* webpackChunkName: "ActivityCode" */ '../views/ActivityCode.vue'),
	},
	{
		path: '/privacy-notice',
		name: 'PrivacyPolicy',
		component: PrivacyPolicy,
	},
	{
		path: '/embargo-policy',
		name: 'EmbargoPolicy',
		meta: { type: 'embargo' },
		component: PrivacyPolicy,
	},
	{
		path: '/business-card-briefcase/:url',
		name: 'CardExchange',
		component: () => import(/* webpackChunkName: "CardExchange" */ '../views/CardExchange.vue'),
	},
	{ path: '/privacy-policy', redirect: '/privacy-notice' },
	{
		path: '/redirect',
		beforeEnter: (to, from, next) => {
			next('/');
		},
	},
	{
		path: '/chat',
		name: 'Chat',
		component: () => import(/* webpackChunkName: "Chat" */ '../views/Chat.vue'),
		props: {
			user: false,
		},
	},
	{
		path: '/edit-profile',
		name: 'EditProfile',
		component: () => import(/* webpackChunkName: "EditProfile" */ '../views/EditProfile.vue'),
	},
	{
		path: '/certificates',
		name: 'Certificates',
		component: () => import(/* webpackChunkName: "Certificates" */ '../views/Certificates.vue'),
	},
	{
		path: '/attendee-list',
		name: 'Attendees',
		component: () => import(/* webpackChunkName: "Attendees" */ '../views/Attendees.vue'),
	},
	{
		path: '/attendee/:id',
		name: 'AttendeeDetail',
		component: () => import(/* webpackChunkName: "Attendees" */ '../views/AttendeeDetail.vue'),
	},
	{
		path: '/auth',
		name: 'Auth',
		component: Auth,
	},
	{
		path: '/event-programme',
		name: 'Agenda',
		component: () => import(/* webpackChunkName: "Agenda" */ '../views/Agenda.vue'),
	},
	{
		path: '/live-programme',
		name: 'Channels',
		component: () => import(/* webpackChunkName: "Channels" */ '../views/Channels.vue'),
	},
	{
		path: '/event-programme/:url',
		name: 'AgendaSession',
		component: () => import(/* webpackChunkName: "AgendaSession" */ '../views/AgendaSession.vue'),
	},
	{
		path: '/speakers',
		name: 'Speakers',
		component: () => import(/* webpackChunkName: "Speakers" */ '../views/Speakers.vue'),
	},
	{
		path: '/event-speaker/:url',
		name: 'AgendaSpeaker',
		component: () => import(/* webpackChunkName: "AgendaSpeaker" */ '../views/AgendaSpeaker.vue'),
	},
	{
		path: '/live/:url',
		name: 'LiveTrack',
		component: () => import(/* webpackChunkName: "LiveTrack" */ '../views/LiveTrack.vue'),
	},
	{
		path: '/on-demand',
		name: 'VideoOnDemand',
		meta: { type: 'vod' },
		component: () => import(/* webpackChunkName: "IframePage" */ '../views/IframePage.vue'),
	},
	{
		path: '/e-posters',
		name: 'VideoOnDemandPosters',
		meta: { type: 'e-posters' },
		component: () => import(/* webpackChunkName: "IframePage" */ '../views/IframePage.vue'),
	},
	{
		path: '/replay-page/:url',
		name: 'ReplayPage',
		component: () => import(/* webpackChunkName: "ReplayPage" */ '../views/ReplayPage.vue'),
	},
	{
		path: '/industry/:url',
		name: 'IndustrySession',
		component: () => import(/* webpackChunkName: "IndustrySession" */ '../views/IndustrySession.vue'),
	},
	{
		path: '/congress-bag',
		name: 'VirtualBag',
		component: () => import(/* webpackChunkName: "VirtualBag" */ '../views/VirtualBag.vue'),
	},
	{
		path: '/my-congress-bag',
		name: 'VirtualBagProfile',
		component: () => import(/* webpackChunkName: "VirtualBagProfile" */ '../views/VirtualBagProfile.vue'),
	},
	{
		path: '/my-agenda',
		name: 'AgendaProfile',
		component: () => import(/* webpackChunkName: "AgendaProfile" */ '../views/AgendaProfile.vue'),
	},
	{
		path: '/scientific-challenges',
		name: 'ScientificChallenges',
		component: () => import(/* webpackChunkName: "ScientificChallenges" */ '../views/ScientificChallenges.vue'),
	},
	{
		path: '/matchmaking',
		name: 'Matchmaking',
		component: () => import(/* webpackChunkName: "Matchmaking" */ '../views/Matchmaking.vue'),
	},
	{
		path: '/recommendations',
		name: 'Recommendations',
		component: () => import(/* webpackChunkName: "ScientificChallenges" */ '../views/Recommendations.vue'),
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		to.meta.fromHistory = savedPosition !== null;
		if (to.name === 'Booth' && to.params && to.params.article) {
			return null;
		} else {
			if (savedPosition) {
				return savedPosition;
			} else {
				return { x: 0, y: 0 };
			}
		}
	},
});

const exhibitionOff = () => {
	Vue.prototype.$modal.show(
		ExhibitionOff,
		{},
		{
			classes: 'login-modal',
			width: '90%',
			maxWidth: 550,
			height: 'auto',
			adaptive: true,
		}
	);
};

router.beforeEach(async (to, from, next) => {
	await platformSettings.loadData();
	Vue.prototype.$Progress.start();
	if (to.name !== 'Auth' && RESTRICTED_PLATFORM === true && session.state.loggedIn === false) {
		window.sessionStorage.setItem('afterLogin', JSON.stringify({ name: to.name, params: to.params }));
		window.location = AUTHURL;
	} else if (
		to.name !== 'Booth' ||
		(session.state.loggedIn === true && platformSettings.state.exhibitionExchange === true) ||
		(session.state.loggedIn === true &&
			platformSettings.state.exhibitionExchange === false &&
			session.state.user &&
			session.state.user.booth_early_accesses &&
			!!session.state.user.booth_early_accesses.find(e => e.url === to.params.url))
	) {
		next();
	} else {
		if (to.name === 'Booth' && platformSettings.state.exhibitionExchange === false) {
			if (to.params.url) {
				$http
					.get(`/booths/${to.params.url}`)
					.then(data => {
						if (data.data) {
							if (data.data.always_accessible === true) {
								next();
							} else {
								Vue.prototype.$Progress.finish();
								exhibitionOff();
								return false;
							}
						}
					})
					.catch(() => {
						Vue.prototype.$Progress.finish();
						exhibitionOff();
						return false;
					});
			} else {
				next(false);
				Vue.prototype.$Progress.finish();
				exhibitionOff();
			}
		} else if (from.name) {
			window.sessionStorage.setItem('afterLogin', JSON.stringify({ name: to.name, params: to.params }));
			Vue.prototype.$Progress.finish();
			Vue.prototype.$modal.show(
				LoginModal,
				{},
				{
					classes: 'login-modal',
					width: '90%',
					maxWidth: 550,
					height: 'auto',
					adaptive: true,
				},
				{
					'before-close': () => {
						next(false);
						window.sessionStorage.removeItem('afterLogin');
					},
				}
			);
		} else {
			next({ name: 'Exhibition' });
		}
	}
});

router.afterEach(() => {
	platformSettings.loader(false);
	Vue.prototype.$Progress.finish();
});

router.beforeResolve((to, from, next) => {
	if (to.name !== 'Booth') {
		Vue.prototype.$gtm.trackEvent({
			event: 'data',
			userId: session.state.user && session.state.user.kitId ? session.state.user.kitId : undefined,
			Exhibitor: '(not set)',
			Booth: '(not set)',
		});
	}
	next();
});

export default router;
