<template>
	<div v-if="visible" class="cookie-info" :class="{ 'is-hiding': agreed }">
		<p>
			We use cookies to make your experience of our website better. By using and further navigating this website you accept this.<br />
			Detailed information about the use of cookies on this website is available on our <router-link :to="{ name: 'PrivacyPolicy' }">Privacy Notice</router-link>.
		</p>
		<div>
			<button @click.prevent="agree">Ok, I understand</button>
		</div>
	</div>
</template>

<script>

	export default {
		name: 'CookieInfo',
		data() {
			return {
				visible: false,
				agreed: false,
			};
		},
		mounted() {
			let storeInfo = window.localStorage.getItem('cookieAgree');
			if (!storeInfo) this.visible = true;
		},
		methods: {
			agree() {
				this.agreed = true;
				window.localStorage.setItem('cookieAgree', 'yes');
				window.setTimeout(() => {
					this.visible = false;
				}, 800);
			},
		},
	};
</script>
