<template>
	<div class="game">
		<router-link v-if="activityChallengeVisible" :to="{ name: 'ActivityChallenge' }">
			<inline-svg :src="require('../assets/game.svg')" width="25" />
			<span>
				<small>Activity challenge</small>
				<span v-if="maxPoints > 0" class="points">
					<progress :value="userPoints" :max="maxPoints" />
					<span>{{ userPoints }}/{{ maxPoints }}</span>
				</span>
			</span>
		</router-link>
	</div>
</template>

<script>
	import $http from '../utils/http.js';
	import platformSettings from '../utils/platformSettings';

	export default {
		name: 'HeaderUserPoints',

		data() {
			return {
				userPoints: null,
				maxPoints: null,
				platformSettings: platformSettings.state,
			};
		},
		computed: {
			activityChallengeVisible() {
				return this.platformSettings && this.platformSettings.gamification_enabled;
			},
		},
		watch: {
			$route: 'getPoints',
		},
		beforeMount() {
			this.getPoints();
		},
		methods: {
			getPoints() {
				$http.get('/user-points', {}).then(({ data }) => {
					this.userPoints = data.user_points || 0;
					this.maxPoints = data.max_points || 0;
				});
			},
		},
	};
</script>
