<template>
	<div>
		<h1>Disclaimer</h1>
		<div class="booth-disclaimer-modal__content content">
			<VueMarkdown v-if="disclaimer" :source="disclaimer" :prerender="prerender" />
		</div>

		<div class="has-text-centered">
			<button class="button is-primary2" @click.prevent="agree">Understood</button>
		</div>
	</div>
</template>

<script>
	import VueMarkdown from 'vue-markdown-v2';
	import { prerender } from '../utils/markdown';
	import { EVENT_NAME } from '../consts';

	export default {
		name: 'BoothDisclaimerModal',
		components: { VueMarkdown },
		props: {
			url: {
				type: String,
				required: true,
			},
			disclaimer: {
				type: String,
				required: true,
			},
		},
		methods: {
			agree() {
				window.localStorage.setItem(`${EVENT_NAME}-${this.url}-disclaimer`, 'true');
				this.cancel();
			},
			cancel() {
				this.$emit('close');
			},
			prerender: prerender,
		},
	};
</script>
