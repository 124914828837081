<template>
	<div class="notification">
		<button v-if="toast" class="delete" @click="$emit('notification:hide')"></button>
		<div v-if="data.image && data.image.url" class="image">
			<img :src="data.image | imageUrl" :alt="data.title" />
		</div>
		<div class="content">
			<div class="title">{{ data.title }}</div>
			<div class="desc">{{ data.description }}</div>
			<Link
				v-if="data.call_to_action"
				:link="data.call_to_action"
				:external-warning="true"
				:analytics="{
					event: 'gaEvent',
					eCategory: 'No Booth Event',
					eAction: 'Notification',
					eLabel: data.title ? data.title : data.call_to_action ? data.call_to_action.text : null,
					Exhibitor: data.gtm_exhibitor ? data.gtm_exhibitor : '(not set)',
					Booth: '(not set)',
				}"
				class="cta button"
				@link:click="$emit('notification:link:click')"
			>
				{{ data.call_to_action.text || 'Click here' }}
			</Link>
		</div>
		<slot />
	</div>
</template>

<script>
	import Link from './Link';

	export default {
		name: 'Notification',

		components: { Link },

		props: {
			data: Object,
			toast: {
				type: Boolean,
				default: false,
			},
		},
	};
</script>
