import { render, staticRenderFns } from "./CardExchange.vue?vue&type=template&id=4ce4bbc4&scoped=true&"
import script from "./CardExchange.vue?vue&type=script&lang=js&"
export * from "./CardExchange.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ce4bbc4",
  null
  
)

export default component.exports