<template>
	<modal
		ref="contentModalWrapper"
		name="content-modal"
		:adaptive="true"
		:width="modalWidth"
		:height="modalHeight"
		:scrollable="modalScrollable"
		:reset="true"
		classes="content-modal-wrapper"
		@before-close="beforeClose"
		@opened="opened"
		@closed="closed"
	>
		<div class="content-modal" :style="{ height: `${boxHeight}px` }">
			<template v-if="imageSrc">
				<div
					ref="modalImage"
					v-dragscroll="!!imageStyle"
					class="modal-image"
					:class="{ zoomed: !!imageStyle }"
					:style="[modalImageStyle, zoomable && !imageStyle && { cursor: 'zoom-in' }, zoomable && !!imageStyle && { cursor: 'move' }]"
					@click="clickImage"
				>
					<img ref="elImage" :src="imageSrc" :style="imageStyle" />
				</div>
				<div class="modal-image-aside">
					<span v-if="element.inner_title || element.title">{{ element.inner_title || element.title }}</span>
					<span v-if="zoomable" class="zoom is-pulled-right" @click="imageZoom">
						<inline-svg :src="require('../assets/zoom.svg')" width="25"></inline-svg>
					</span>
				</div>
			</template>
			<video v-show="type === 'youtube' || type === 'video_local' || type === 'video_external'" ref="videoPlayer" class="video-js vjs-big-play-centered"></video>
			<div v-if="type === 'text'" ref="article" class="modal-text">
				<VueMarkdown v-if="element.text" :source="element.text" class="content" :prerender="prerender" @rendered="rendered" />
			</div>
			<div v-show="type === 'pdf_local'" class="modal-pdf">
				<div ref="pdfElement"></div>
				<!-- embed :src="element.file | imageUrl" type="application/pdf" width="100%" height="100%" / -->
			</div>
			<div v-if="type === 'download_local' || type === 'download_external'" class="download-modal">
				<div class="modal-icon modal-success animate">
					<span class="modal-line modal-tip animateSuccessTip"></span>
					<span class="modal-line modal-long animateSuccessLong"></span>
					<div class="modal-placeholder"></div>
					<div class="modal-fix"></div>
				</div>
				<h1>File has been downloaded</h1>
			</div>
		</div>
		<span v-if="closeButton" class="close" @click="close">&times;</span>
	</modal>
</template>

<script>
	import videojs from 'video.js';
	import 'videojs-youtube';
	import '@devmobiliza/videojs-vimeo/dist/videojs-vimeo.cjs';
	import VueMarkdown from 'vue-markdown-v2';
	import PDFObject from 'pdfobject';
	import { prerender } from '../utils/markdown';
	import { downloadFile } from '../utils/download';
	import { dragscroll } from 'vue-dragscroll';

	const videoOptions = { autoplay: 'play', controls: true, fill: true };

	export default {
		name: 'ContentModal',
		components: { VueMarkdown },
		directives: {
			dragscroll: dragscroll,
		},
		props: {
			element: {
				type: Object,
			},
		},
		data() {
			return {
				player: null,
				modalWidth: 1200,
				modalHeight: 'auto',
				boxHeight: null,
				modalScrollable: false,
				imageSrc: null,
				closeButton: true,
				zoomable: false,
				imageStyle: null,
				imageWidth: null,
				imageHeight: null,
				modalImageStyle: null,
			};
		},
		computed: {
			type() {
				if (!this.element) return;
				return this.element.type;
			},
		},
		beforeDestroy() {
			if (this.player) {
				this.player.dispose();
			}
		},
		methods: {
			show() {
				this.$nextTick(function() {
					if (this.type === 'image_local' || this.type === 'image_external') {
						let maxWidth = window.innerWidth;
						let maxHeight = window.innerHeight - 250;
						let preloadImg = new Image();
						let src;
						if (this.type === 'image_local') {
							src = this.$options.filters.imageUrl(this.element.file);
						} else {
							src = this.element.url;
						}

						preloadImg.onload = () => {
							let calcWidth = preloadImg.width;
							let calcHeight = preloadImg.height;

							this.imageWidth = preloadImg.width;
							this.imageHeight = preloadImg.height;

							if (calcWidth > 1400) {
								calcWidth = 1400;
							}
							if (calcHeight > maxHeight) {
								calcHeight = maxHeight;
								calcWidth = (calcHeight * preloadImg.width) / preloadImg.height;
							}
							let modalWidth = calcWidth + 32 < maxWidth ? calcWidth + 32 : maxWidth - 64;
							if (modalWidth > 200) {
								this.modalWidth = modalWidth;
							} else {
								this.modalWidth = 200;
							}

							if (calcWidth < preloadImg.width) {
								this.zoomable = true;
							}
							this.modalHeight = 'auto';
							this.modalScrollable = true;
							this.$refs.contentModalWrapper.setInitialSize();
							this.$nextTick(function() {
								this.imageSrc = src;
								this.$modal.show('content-modal');
							});
						};

						preloadImg.src = src;
					} else if (this.type === 'text' || this.type === 'pdf_local') {
						this.modalWidth = 1300;
						this.modalHeight = 'auto';
						this.$nextTick(() => {
							this.$modal.show('content-modal');
						});
					} else if (this.type === 'download_external') {
						this.closeButton = false;
						this.modalWidth = 500;
						this.modalHeight = 220;
						if (this.element.url) {
							downloadFile(this.element.url);
						}
						this.$nextTick(function() {
							this.$modal.show('content-modal');
						});
						window.setTimeout(() => {
							this.$modal.hide('content-modal');
						}, 3000);
					} else if (this.type === 'download_local') {
						this.closeButton = false;
						this.modalWidth = 500;
						this.modalHeight = 220;
						if (this.element.file) {
							downloadFile(this.$options.filters.imageUrl(this.element.file), this.element.file.name);
						}
						this.$nextTick(function() {
							this.$modal.show('content-modal');
						});
						window.setTimeout(() => {
							this.$modal.hide('content-modal');
						}, 3000);
					} else {
						this.$modal.show('content-modal');
					}
				});
			},
			opened() {
				if (this.type === 'youtube') {
					let type = 'youtube',
						src,
						options;
					if (this.element.url.indexOf('vimeo.com') !== -1) type = 'vimeo';
					if (type === 'youtube') {
						src = this.element.url.indexOf('youtube.com') !== -1 ? this.element.url : `https://www.youtube.com/watch?v=${this.element.url}`;
						options = { ...videoOptions, techOrder: ['youtube'], sources: [{ type: 'video/youtube', src }] };
					} else if (type === 'vimeo') {
						src = this.element.url;
						options = { ...videoOptions, techOrder: ['vimeo'], sources: [{ type: 'video/vimeo', src }] };
					}
					this.player = videojs(this.$refs.videoPlayer, options, () => {
						this.player.play();
					});
				} else if (this.type === 'video_local') {
					let options = { ...videoOptions, sources: [{ type: this.element.file.mime, src: this.$options.filters.imageUrl(this.element.file) }] };
					this.player = videojs(this.$refs.videoPlayer, options, () => {
						this.player.play();
					});
				} else if (this.type === 'video_external') {
					let src = this.element.url;
					let options = { ...videoOptions, sources: [{ src }] };
					this.player = videojs(this.$refs.videoPlayer, options, () => {
						this.player.play();
					});
				} else if (this.type === 'pdf_local') {
					PDFObject.embed(this.$options.filters.imageUrl(this.element.file), this.$refs.pdfElement);
				}
			},
			closed() {
				this.modalWidth = 1200;
				this.modalHeight = 'auto';
				this.modalScrollable = false;
				this.imageSrc = null;
			},
			close() {
				this.$modal.hide('content-modal');
			},
			beforeClose() {
				this.player = null;
				this.modalWidth = 1200;
				this.modalHeight = 'auto';
				this.boxHeight = null;
				this.modalScrollable = false;
				this.imageSrc = null;
				this.closeButton = true;
				this.imageStyle = null;
				this.imageWidth = null;
				this.imageHeight = null;
				this.modalImageStyle = null;
			},
			prerender: prerender,
			rendered() {
				this.$nextTick(() => {
					if (this.$refs.article) {
						let vids = this.$refs.article.getElementsByTagName('video');
						if (vids) {
							for (let v of vids) {
								videojs(v);
							}
						}
					}
				});
			},
			clickImage() {
				if (!this.imageStyle) {
					this.imageZoom();
				}
			},
			imageZoom() {
				let modal;
				if (this.$refs.contentModalWrapper && this.$refs.contentModalWrapper.$refs && this.$refs.contentModalWrapper.$refs.modal) {
					modal = this.$refs.contentModalWrapper.$refs.modal;
				}
				if (!this.imageStyle) {
					let maxWidth = window.innerWidth;
					let maxHeight = window.innerHeight - 280;

					let scbar = window.innerWidth - document.documentElement.clientWidth;
					if (scbar > 20) {
						scbar = 20;
					}
					let hg = this.$refs.modalImage.clientHeight;
					this.modalImageStyle = { height: `${hg}px`, overflow: 'scroll' };
					this.imageStyle = { width: `${this.imageWidth}px`, height: `${this.imageHeight}px`, maxWidth: 'none' };

					let modalWidth, newHeight;

					if (this.modalWidth < 700) {
						let testWidth = this.imageWidth + 60 + scbar;
						modalWidth = testWidth < 1400 ? testWidth : 1400;
					}
					if (modalWidth > maxWidth) {
						modalWidth = maxWidth;
					}

					if (hg < 300) {
						let testHeight = this.imageHeight;
						newHeight = testHeight < 1400 ? testHeight : 1400;
						if (newHeight > maxHeight) {
							newHeight = maxHeight;
						}
						this.modalImageStyle.height = `${newHeight}px`;
					}

					if (modal && modalWidth !== this.modalWidth) {
						let marginLeft = (this.modalWidth - modalWidth) / 2;
						modal.style.width = `${modalWidth}px`;
						modal.style.marginLeft = `${marginLeft}px`;
					}
				} else {
					if (modal) {
						modal.style.width = `${this.modalWidth}px`;
						modal.style.marginLeft = '';
					}

					this.imageStyle = null;
					this.modalImageStyle = null;
				}
			},
			mouseover(event) {
				let x = event.offsetX;
				let y = event.offsetY;
				this.$refs.elImage.style.top = `-${y}px`;
				this.$refs.elImage.style.left = `-${x}px`;
			},
		},
	};
</script>
