<template>
	<modal name="blocked-modal" :adaptive="true" :width="500" height="auto" :reset="true">
		<div class="blocked-modal">
			<h1>Chat blocked</h1>
			<p>
				If the live chat function does not appear to work, please disable the ad blocker from your browser settings.
			</p>
			<div class="buttons">
				<a class="button is-primary is-outlined" @click.prevent="cancel()">Close</a>
			</div>
		</div>
	</modal>
</template>

<script>
	export default {
		name: 'BlockedModal',
		methods: {
			cancel() {
				this.$modal.hide('blocked-modal');
			},
		},
	};
</script>

<style scoped></style>
