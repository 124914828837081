<template>
	<div class="dcp__dd" :class="[`is-${type}`, { 'is-active': active && isTime }]">
		<div ref="adslide" class="glide glide-fade">
			<div class="glide__track" data-glide-el="track">
				<ul class="glide__slides">
					<li v-for="ad in ads" :key="ad.id" class="glide__slide">
						<Link v-if="ad.link" :link="ad.link" :analytics="getAnalytics(ad)">
							<img :src="ad.banner | imageUrl" />
						</Link>
						<img v-else :src="ad.banner | imageUrl" />
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	import Link from '../components/Link';
	import $http from '../utils/http';
	import Glide from '@glidejs/glide';

	const BANNER_SWITCH_DELAY = 7 * 1000;
	const CONGRESS_TIME_SWITCH_DELAY = 30 * 1000;

	export default {
		name: 'Advert',
		components: { Link },
		props: {
			type: {
				type: String,
				required: true,
			},
		},
		data() {
			return {
				ads: [],
				active: false,
				interval: false,
				timeout: false,
				glide: false,
			};
		},
		computed: {
			isTime() {
				return this.type === 'time';
			},
			eAction() {
				if (this.type === 'profile') {
					return 'Networking Ad';
				} else if (this.type === 'time') {
					return 'Congress Time Ad';
				} else if (this.type === 'time-mobile') {
					return 'Congress Time Ad';
				} else if (this.type === 'time-modal') {
					return 'Clock Popup Ad';
				}
			},
		},
		beforeDestroy() {
			this.cleanup();
		},
		mounted() {
			this.cleanup();
			let serverType;
			if (this.type === 'profile') {
				serverType = 'networking_ad';
			} else if (this.type === 'time') {
				serverType = 'congress_time_ad';
			} else if (this.type === 'time-mobile') {
				serverType = 'congress_time_ad';
			} else if (this.type === 'time-modal') {
				serverType = 'congress_popup_ad';
			}

			$http.get(`/advertisements?type=${serverType}`).then(data => {
				if (data && data.data) {
					this.ads = data.data;

					if (this.ads.length > 0) {
						this.$nextTick(() => {
							this.glide = new Glide(this.$refs.adslide, {
								type: 'slider',
								autoplay: this.type !== 'time' ? BANNER_SWITCH_DELAY : false,
								gap: 0,
							}).mount();

							if (this.type === 'time' && this.ads.length > 0) {
								this.active = false;
								this.glide.pause();
								this.startTimeInterval();
							}
						});
					}
				}
			});
		},
		methods: {
			sliderClicked(i, index) {
				if (i >= 0) {
					let slide = this.ads[i];
					if (slide && slide.link) {
						this.$gtm.dataLayer().push({
							event: 'gaEvent',
							eCategory: 'No Booth Event',
							eAction: this.eAction,
							eLabel: slide.title ? slide.title : slide.link ? slide.link.text : null,
							Exhibitor: slide.gtm ? slide.gtm : '(not set)',
							Booth: '(not set)',
						});
					}
				}
			},
			getAnalytics(ad) {
				if (ad && ad.link) {
					return {
						event: 'gaEvent',
						eCategory: 'No Booth Event',
						eAction: this.eAction,
						eLabel: ad.title ? ad.title : ad.link ? ad.link.text : null,
						Exhibitor: ad.gtm ? ad.gtm : '(not set)',
						Booth: '(not set)',
					};
				}
				return null;
			},
			startTimeInterval() {
				this.timeout = window.setTimeout(() => {
					this.active = true;
					this.startTimeCountdown();
				}, CONGRESS_TIME_SWITCH_DELAY);
			},
			startTimeCountdown() {
				this.timeout = window.setTimeout(() => {
					this.active = false;
					window.setTimeout(() => {
						this.glide.go('>');
						this.glide.pause();
					}, 1000);
					this.startTimeInterval();
				}, BANNER_SWITCH_DELAY);
			},
			cleanup() {
				if (this.interval) {
					window.clearInterval(this.interval);
				}
				if (this.timeout) {
					window.clearTimeout(this.timeout);
				}
			},
		},
	};
</script>
