<template>
	<component
		:is="item.type === 'external' ? 'a' : 'router-link'"
		:to="linkTo"
		:href="item.type === 'external' ? item.url : null"
		:target="item.external ? '_blank' : null"
		:class="{ 'has-icon': !!item.icon }"
	>
		<span v-if="item.icon && !mobile" class="icon" :class="{ 'is-large': !!item.icon_only }"><inline-svg :src="item.icon | imageUrl" width="35"></inline-svg></span>
		<span v-if="!item.icon_only" class="text">
			<template v-if="item.text === 'e-Posters'"> <span>e-</span>Posters </template>
			<template v-else>{{ item.text }}</template>
		</span>
	</component>
</template>

<script>
	export default {
		name: 'HeaderNavItem',
		props: {
			item: Object,
			mobile: {
				type: Boolean,
				default: false,
			},
		},
		computed: {
			linkTo() {
				if (this.item && this.item.type !== 'external') {
					switch (this.item.type) {
						case 'article':
							return { name: 'Article', params: { url: this.item.url } };
						case 'homepage':
							return { name: 'Homepage' };
						case 'exhibition':
							return { name: 'Exhibition' };
						case 'nasvillage':
							return { name: 'NasVillage' };
						case 'booth':
							return { name: 'Booth', params: { url: this.item.url } };
						default:
							return {};
					}
				}
			},
		},
	};
</script>
