<template>
	<router-link v-if="link && !isExternal && linkTo" :to="linkTo" :class="className" @click.native="nativeClick">
		<slot></slot>
	</router-link>
	<component
		:is="link && isExternal ? 'a' : 'span'"
		v-else
		:href="isExternal && !showWarning ? url : null"
		:target="blank && '_blank'"
		:class="className"
		@click.prevent="linkClick($event)"
	>
		<slot></slot>
	</component>
</template>

<script>
	import ExternalDisclaimer from '../components/ExternalDisclaimer';
	import linkRoute from '../utils/link';

	export default {
		name: 'Link',
		props: {
			link: {
				type: Object,
			},
			externalWarning: Boolean,
			analytics: Object,
			className: String,
		},
		computed: {
			isExternal() {
				if (!this.link || !this.link.type) return;
				return (
					this.link.type === 'external' ||
					this.link.type === 'safelink' ||
					this.link.type === 'externalsafe' ||
					this.link.type === 'link_external' ||
					this.link.type === 'specialchat'
				);
			},
			url() {
				if (!this.link || !this.link.url) return;
				return this.link.url;
			},
			blank() {
				if (!this.link || !this.link.target) return;
				return this.link.target;
			},
			linkTo() {
				if (!this.link || !this.link.type) return;
				if (this.link.type !== 'external' && this.link.type !== 'link_external' && this.link.type !== 'externalsafe' && this.link.type !== 'safelink')
					return linkRoute(this.link.type, this.link.url);
				return this.isExternal ? null : {};
			},
			showWarning() {
				if (!this.link) return;
				return this.externalWarning === true && this.link.type !== 'externalsafe' && this.link.type !== 'safelink';
			},
		},
		methods: {
			nativeClick() {
				this.$emit('link:click');
				if (this.analytics) {
					this.$gtm.dataLayer().push(this.analytics);
				}
			},
			linkClick(ev) {
				this.$emit('link:click');

				if (this.analytics && !this.showWarning) {
					if (this.url && this.isExternal) {
						ev.preventDefault();
					}
					this.$gtm.dataLayer().push(this.analytics);
					if (this.url && this.isExternal) {
						if (this.blank) {
							window.open(this.url, '_blank');
						} else {
							window.location = this.url;
						}
					}
				}
				if (this.link.type === 'specialchat') {
					ev.preventDefault();
					if (window.tidioChatApi) {
						window.tidioChatApi.open();
					} else if (window.tidioKey) {
						this.$modal.show('blocked-modal');
					}
				} else if (this.showWarning) {
					ev.preventDefault();
					this.$modal.show(
						ExternalDisclaimer,
						{
							url: this.url,
							target: this.blank,
							analytics: this.analytics,
						},
						{
							classes: 'external-disclaimer',
							width: '90%',
							maxWidth: 550,
							height: 'auto',
							adaptive: true,
						}
					);
				}
			},
		},
	};
</script>
