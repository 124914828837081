const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
const isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;

export const downloadFile = function(sUrl, fName) {
	/*
		ToDo: test
		//iOS devices do not support downloading. We have to inform user about this.
			if (/(iP)/g.test(navigator.userAgent)) {
				alert('Your device does not support files downloading. Please try again in desktop browser.');
				return false;
	}*/

	//If in Chrome or Safari - download via virtual link click
	if (isChrome || isSafari) {
		//Creating new link node.
		let link = document.createElement('a');
		link.href = sUrl;
		link.target = '_blank';

		if (link.download !== undefined) {
			let fileName = fName || sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
			link.download = fileName;
		}

		//Dispatching click event.
		if (document.createEvent) {
			let e = document.createEvent('MouseEvents');
			e.initEvent('click', true, true);
			link.dispatchEvent(e);
			return true;
		}
	}

	window.open(sUrl, '_blank');
	return true;
};
