<template>
	<div class="header-chat">
		<router-link :to="{ name: 'Chat' }" :class="{ 'is-active': $route.name === 'Chat' }">
			<span>
				<inline-svg :src="require('../assets/topic.svg')" width="26"></inline-svg>
				<i v-if="messageCount > 0">{{ displayCount }}</i>
			</span>
		</router-link>
		<CometChatConversationListSimple v-if="!static" />
	</div>
</template>

<script>
	import $http from '../utils/http';
	import { CometChat } from '@cometchat-pro/chat';
	import CometChatConversationListSimple from '../cc3/components/Chats/CometChatConversationListSimple/CometChatConversationListSimple';

	const sumValues = obj => Object.values(obj).reduce((a, b) => a + b);

	export default {
		name: 'HeaderChat',
		components: { CometChatConversationListSimple },
		props: {
			static: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				messageCount: 0,
				interval: false,
			};
		},
		computed: {
			displayCount() {
				return this.messageCount > 9 ? '9+' : this.messageCount;
			},
		},
		mounted() {
			this.$nextTick(() => {
				if (!this.static) {
					this.onLoad();
				}
			});
		},
		beforeDestroy() {
			if (this.interval) {
				window.clearInterval(this.interval);
			}
		},
		methods: {
			getCount() {
				CometChat.getUnreadMessageCountForAllUsers().then(array => {
					if (Object.values(array).length > 0) {
						this.$nextTick(() => {
							this.messageCount = sumValues(array);
						});
					} else {
						this.messageCount = 0;
					}
				});
			},
			initInterval() {
				this.interval = window.setInterval(() => {
					this.getCount();
				}, 30000);
			},
			initListeners() {
				CometChat.addMessageListener(
					'headerListener',
					new CometChat.MessageListener({
						onTextMessageReceived: textMessage => {
							this.messageCount += 1;
						},
						onMessagesRead: messageReceipt => {
							this.messageCount -= 1;
						},
					})
				);
			},
			onLoad() {
				CometChat.getLoggedInUser().then(
					user => {
						this.getCount();
						this.initInterval();
						this.initListeners();
					},
					() => {
						$http.get('/chat-auth/').then(({ data }) => {
							CometChat.login(data.auth).then(
								User => {
									//console.log('Login Successful:', { User });
									// User loged in successfully.
									this.getCount();
									this.initInterval();
									this.initListeners();
								},
								error => {
									//console.log('Login failed with exception:', { error });
									// User login failed, check error and take appropriate action.
								}
							);
						});
					}
				);
			},
		},
	};
</script>
