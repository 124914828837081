var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.element)?_c('div',{class:[
		("banner" + _vm.index),
		{
			'is-hovered': _vm.highlighted === _vm.index,
			'is-other-hovered': _vm.highlighted && _vm.highlighted !== _vm.index,
			'is-restricted': _vm.element.restricted,
			'is-none': _vm.element.type === 'none',
			'is-video': _vm.video,
		} ],on:{"mouseenter":_vm.onMouseEnter,"mouseleave":_vm.onMouseLeave,"click":_vm.onClick}},[(_vm.image)?_c('img',{attrs:{"src":_vm.image}}):_vm._e(),(_vm.svg)?_c('inline-svg',{attrs:{"src":_vm.svg}}):_vm._e(),(_vm.video)?_c('video',{ref:"video",attrs:{"muted":"","autoplay":"true","loop":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.video,"type":_vm.element.banner.mime}})]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }