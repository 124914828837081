<template>
	<div>
		<strong>{{ userName }}</strong>
		<span
			>{{ userProfession }}<br />
			{{ userCountry }}</span
		>
		<hr />
		<router-link :to="{ name: 'EditProfile' }"><inline-svg :src="require('../assets/edit2.svg')" width="24" height="24"></inline-svg> My Profile</router-link>
		<hr />
		<router-link :to="{ name: 'AgendaProfile' }"><inline-svg :src="require('../assets/timetable.svg')" width="24" height="24"></inline-svg> My Agenda</router-link>
		<hr v-if="hasEarlyAccess" />
		<span v-if="hasEarlyAccess">Booth Agent</span>
		<ul v-if="hasEarlyAccess">
			<li v-for="booth in session.user.booth_early_accesses" :key="booth.id">
				-&nbsp;<router-link :to="{ name: 'Booth', params: { url: booth.url } }">{{ booth.title }}</router-link>
			</li>
		</ul>
		<hr v-if="hasBCB" />
		<span v-if="hasBCB">Business Card Briefcase</span>
		<ul v-if="hasBCB">
			<li v-for="booth in session.user.bs_card_exchange_booths_access" :key="booth.id">
				-&nbsp;<router-link :to="{ name: 'CardExchange', params: { url: booth.url } }">{{ booth.title }}</router-link>
			</li>
		</ul>
		<hr />
		<router-link :to="{ name: 'Attendees' }"><inline-svg :src="require('../assets/conference.svg')" width="24" height="24"></inline-svg> Attendees List</router-link>
		<hr v-if="showCertificates" />
		<router-link v-if="showCertificates" :to="{ name: 'Certificates' }"
			><inline-svg :src="require('../assets/diploma.svg')" width="24" height="24"></inline-svg> Certificates</router-link
		>
		<hr />
		<router-link :to="{ name: 'Article', params: { url: 'support' } }"
			><inline-svg :src="require('../assets/online-support.svg')" width="24" height="24"></inline-svg> Help &amp; Support</router-link
		>
		<template v-if="activityChallengeVisible">
			<hr />
			<router-link :to="{ name: 'ScientificChallenges' }"><inline-svg :src="require('../assets/test.svg')" width="24" height="24"></inline-svg> Scientific Challenge</router-link>
		</template>
		<hr />
		<a @click="logout"><inline-svg :src="require('../assets/logout.svg')" width="22"></inline-svg> Logout</a>
	</div>
</template>

<script>
	import session from '../utils/session';
	import platformSettings from '../utils/platformSettings';

	export default {
		name: 'HeaderUserMenu',
		data() {
			return {
				session: session.state,
				platformSettings: platformSettings.state,
			};
		},
		computed: {
			userName() {
				return `${this.session.user.firstName} ${this.session.user.lastName}`;
			},
			userProfession() {
				if (typeof this.session.user.user_profession === 'object') {
					return this.session.user.user_profession?.name;
				}
				return '';
			},
			userCountry() {
				if (typeof this.session.user.user_country === 'object') {
					return this.session.user.user_country?.name;
				}
				return '';
			},
			hasBCB() {
				return this.session.user && this.session.user.bs_card_exchange_booths_access ? !!this.session.user.bs_card_exchange_booths_access.length : false;
			},
			hasEarlyAccess() {
				return this.session.user && this.session.user.booth_early_accesses ? !!this.session.user.booth_early_accesses.length : false;
			},
			activityChallengeVisible() {
				return this.platformSettings && this.platformSettings.gamification_enabled;
			},
			showCertificates() {
				return this.platformSettings.certificatesDownloadEnabled === true;
			},
		},
		methods: {
			logout() {
				session.logout();
			},
		},
	};
</script>

<style scoped></style>
