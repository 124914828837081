import { APIURL, ENV, MEDIAURL, MEDIAURLCDN } from '../consts';

export const imageUrl = (imageObject, size) => {
	if (imageObject.provider === 'local') {
		if (size && imageObject.formats && imageObject.formats[size]) {
			return `${APIURL}${imageObject.formats[size].url}`;
		} else {
			return `${APIURL}${imageObject.url}`;
		}
	} else if (imageObject.provider === 'azure-storage') {
		if (size && imageObject.formats && imageObject.formats[size]) {
			if (ENV === 'production') {
				return imageObject.formats[size].url.replace(MEDIAURL + '/', MEDIAURLCDN + '/');
			} else {
				return imageObject.formats[size].url;
			}
		} else {
			if (ENV === 'production') {
				return imageObject.url.replace(MEDIAURL + '/', MEDIAURLCDN + '/');
			} else {
				return imageObject.url;
			}
		}
	}
};
