<template>
	<footer class="footer">
		<div v-if="footerBoxes" class="footer-boxes">
			<div v-for="(box, index) in footerBoxes.footer_text" class="footer-box content" :class="{ 'is-full': index === 3 }">
				<h2 class="title is-4">{{ box.header }}</h2>
				<VueMarkdown :source="box.text" />
			</div>
		</div>
		<div class="footer-content">
			<div class="footer-logo">
				<img class="img-logo" :src="footerLogoGraphic" />
				<img class="text-logo" :src="footerLogoText" />
			</div>
			<div class="footer-copy">
				<p>EAACI 2022 Copyright. All rights reserved.</p>
				<router-link :to="{ name: 'PrivacyPolicy' }">Privacy Notice</router-link>&nbsp;|&nbsp;
				<router-link :to="{ name: 'EmbargoPolicy' }">Embargo Policy</router-link>
			</div>
			<div class="footer-socials">
				<span>Follow us</span>
				<a href="https://www.facebook.com/EAACI" target="_blank"><inline-svg :src="require('../assets/facebook.svg')" width="25"></inline-svg></a>
				<a href="https://twitter.com/EAACI_HQ" target="_blank"><inline-svg :src="require('../assets/twitter.svg')" width="25"></inline-svg></a>
				<a href="https://www.linkedin.com/company/european-academy-of-allergy-and-clinical-immunology---eaaci/" target="_blank"
					><inline-svg :src="require('../assets/linkedin.svg')" width="25"></inline-svg
				></a>
				<a href="https://www.youtube.com/user/EAACIHeadquarters" target="_blank"
					><inline-svg :src="require('../assets/youtube.svg')" width="25"></inline-svg
				></a>
				<a href="https://www.instagram.com/eaaci/" target="_blank"><inline-svg :src="require('../assets/instagram.svg')" width="25"></inline-svg></a>
			</div>
		</div>
	</footer>
</template>

<script>
	import $http from '../utils/http.js';
	import VueMarkdown from 'vue-markdown-v2';
	import platformSettings from '../utils/platformSettings';

	export default {
		name: 'Footer',
		components: { VueMarkdown },
		data() {
			return {
				footerBoxes: [],
				platformSettings: platformSettings.state,
			};
		},
		computed: {
			footerLogoGraphic() {
				if (this.platformSettings.footerLogoGraphic) {
					return this.$options.filters.imageUrl(this.platformSettings.footerLogoGraphic);
				}
				return require('../assets/logo.png');
			},
			footerLogoText() {
				if (this.platformSettings.footerLogoText) {
					return this.$options.filters.imageUrl(this.platformSettings.footerLogoText);
				}
				return require('../assets/logow.svg');
			},
		},
		mounted() {
			$http.get('/homepage/footer').then(
				data => {
					this.footerBoxes = data.data;
				},
				() => {}
			);
		},
	};
</script>
