import Vue from 'vue';
import { InlineSvgPlugin } from 'vue-inline-svg';
import VueGtm from 'vue-gtm';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VModal from 'vue-js-modal';
import { vfmPlugin } from 'vue-final-modal';
import VueProgressBar from 'vue-progressbar';
import VueTour from 'vue-tour';
import { CometChat } from '@cometchat-pro/chat';
import vSelect from 'vue-select';
import VueAppInsights from 'vue-application-insights';
import vClickOutside from 'v-click-outside';

import App from './App.vue';
import router from './router';
import { APPINSIGHTS } from '@/consts';
import './utils/filters.js';

import './scss/dcw.scss';
import './css/booth.css';
import 'video.js/dist/video-js.css';

import 'swiper/css/swiper.css';

const cometAppId = process.env.VUE_APP_COMMETCHAT_APP_ID;
const cometRegion = process.env.VUE_APP_COMMETCHAT_REGION;

let cometChatAppSetting = new CometChat.AppSettingsBuilder()
	.subscribePresenceForAllUsers()
	.setRegion(cometRegion)
	.build();

CometChat.init(cometAppId, cometChatAppSetting).then(
	() => {
		console.log('Initialization completed successfully');
	},
	error => {
		console.log('Initialization failed with error:', error);
	}
);

Vue.use(InlineSvgPlugin);
Vue.use(VueAwesomeSwiper);
Vue.use(VModal, { dynamic: true, injectModalsContainer: true });
Vue.use(vfmPlugin);
Vue.use(VueProgressBar, {
	color: '#c272bc',
	failedColor: 'red',
	height: '4px',
});

Vue.use(VueTour);
Vue.use(vClickOutside);

Vue.component('v-select', vSelect);

Vue.config.productionTip = false;

Vue.use(VueGtm, {
	id: 'GTM-TB7B4CC',
	enabled: true,
	debug: false,
	loadScript: true,
	vueRouter: router,
	ignoredViews: ['Auth', 'Booth'],
});

if (APPINSIGHTS !== 'false') {
	Vue.use(VueAppInsights, {
		id: APPINSIGHTS,
	});
}

new Vue({
	router,
	render: h => h(App),
}).$mount('#app');
