<template>
	<header class="header" :class="{ 'is-homepage': isHomepage }">
		<aside :class="{ 'is-mobile-tracks': mobileTracks }">
			<div class="mobile-title-track">
				<inline-svg :src="require('../assets/video-call.svg')" width="25" />
				<span>Live now</span>
			</div>
			<div class="mobile-title-clock">
				<inline-svg :src="require('../assets/clock.svg')" width="25" />
				<span>Congress time</span>
			</div>
			<a class="mobile-close" @click.prevent="closeMobile"><inline-svg :src="require('../assets/delete.svg')" width="25"/></a>
			<LiveNow v-if="platformSettings.liveNow" class="header-news" @tracks="setTracks" />
			<div v-else></div>

			<div class="mobile-tracks">
				<router-link v-for="tr in tracks" :key="tr.id" :to="{ name: 'LiveTrack', params: { url: tr.url } }" class="mobile-track">
					<strong>{{ tr.title }}</strong>
				</router-link>
			</div>

			<div class="header-user" :class="{ 'is-logged': isLogged }">
				<HeaderUserPoints v-if="isLogged" class="activity-challenge" />

				<Notifications />

				<div v-if="isLogged && userPublic" class="header-attendees">
					<router-link :to="{ name: 'Attendees' }">
						<span>
							<inline-svg :src="require('../assets/conference.svg')" width="26"></inline-svg>
						</span>
					</router-link>
				</div>

				<HeaderChat v-if="isLogged && userPublic && platformSettings.chat" />

				<a v-if="!isLogged" key="register" :href="registerUrl">
					<span><inline-svg :src="require('../assets/register.svg')" width="25"></inline-svg></span>
					<span>Register</span>
				</a>
				<a v-if="!isLogged" key="login" :href="authUrl">
					<span><inline-svg :src="require('../assets/login.svg')" width="25"></inline-svg></span>
					<span>Login</span>
				</a>

				<div v-if="isLogged" class="header-username">
					<div class="image" :class="{ bg: !userImage }">
						<inline-svg v-if="!userImage && userGender === 'Male'" :src="require('../assets/user_male.svg')" width="25" />
						<inline-svg v-else-if="!userImage && userGender === 'Female'" :src="require('../assets/user_female.svg')" width="25" />
						<inline-svg v-else-if="!userImage" :src="require('../assets/user_generic.svg')" width="25" />
						<img v-else :src="userImage | imageUrl('thumbnail')" />
					</div>
					<router-link :to="{ name: 'EditProfile' }">
						<span>{{ userName }}</span>
						<span><inline-svg :src="require('../assets/expand.svg')" width="15"></inline-svg></span>
					</router-link>
					<HeaderUserMenu class="header-user-menu" />
				</div>
			</div>
		</aside>
		<div class="header-main-wrapper" :class="{ 'is-mobile-open': mobileMenu }">
			<div class="header-main">
				<h1>
					<span>{{ platformSettings.siteName }}</span>
					<router-link :to="{ name: 'Homepage' }">
						<img class="img-logo" :src="headerLogoGraphic" />
						<img v-if="!mobileMenu" class="text-logo" :src="headerLogoText" />
						<img v-if="mobileMenu" class="text-logo" :src="footerLogoText" />
					</router-link>
				</h1>
				<nav :class="{ 'on-tour': $tours && $tours['dcwTour'] && [0, 1, 3].indexOf($tours['dcwTour'].currentStep) !== -1 }">
					<ul>
						<li v-for="item in menu" :key="item.id">
							<HeaderNavItem :item="item"></HeaderNavItem>
							<ul v-if="item.special_type === 'infopages'">
								<li v-for="article in info" :key="article.url" :data-menu-info="article.id">
									<router-link v-if="article.url" :to="{ name: 'Article', params: { url: article.url } }">
										<span class="text">{{ article.name }}</span>
									</router-link>
								</li>
							</ul>
							<ul v-if="item.special_type === 'exhibitionMenu'">
								<li>
									<router-link :to="{ name: 'Exhibition' }">
										<span class="text">Exhibition Hall</span>
									</router-link>
								</li>
								<li>
									<router-link :to="{ name: 'NasVillage' }">
										<span class="text">NAS Village</span>
									</router-link>
								</li>
							</ul>
							<ul v-if="item.children">
								<li v-for="item in item.children" :key="item.id" :data-menu-item="item.id">
									<HeaderNavItem :item="item"></HeaderNavItem>
								</li>
							</ul>
						</li>
					</ul>
				</nav>

				<div class="header-burger" :class="{ 'is-open': mobileMenu }" @click="toggleMobileMenu"><span></span><span></span><span></span><span></span></div>
			</div>
			<div v-if="platformSettings.congressTimeBox" class="header-time">
				<div class="header-time-content" @click.prevent="timeBoxClick">
					<div class="header-time-congress">
						<span>Congress time - CET</span>
						<strong>{{ formattedTime }}</strong>
					</div>
					<small v-if="showLocalTime"
						>Your local time: <strong>{{ formattedLocalTime }}</strong></small
					>
				</div>
				<Advert type="time" />
			</div>
			<div v-else-if="!platformSettings.congressTimeBox && !!platformSettings.closureCountdown && deadline >= 0" class="header-time is-countdown">
				<span>Remaining days<br />to platform closure</span>
				<strong>{{ deadline }}</strong>
				<Advert type="time" />
			</div>
		</div>
		<div class="header-mobile-menu" :class="{ 'is-open': mobileMenu }">
			<div class="header-user-mobile" :class="{ 'is-logged': isLogged }">
				<div v-if="!isLogged" class="buttons">
					<a class="button is-primary is-inverted" :href="authUrl">
						<span>Login</span>
					</a>
					<a class="button is-primary is-inverted" :href="registerUrl">
						<span>Register</span>
					</a>
				</div>

				<div v-if="isLogged" class="header-username">
					<div class="username" @click.prevent="toggleMobileUserMenu">
						<div class="image" :class="{ bg: !userImage }">
							<inline-svg v-if="!userImage && userGender === 'Male'" :src="require('../assets/user_male.svg')" width="25" />
							<inline-svg v-else-if="!userImage && userGender === 'Female'" :src="require('../assets/user_female.svg')" width="25" />
							<inline-svg v-else-if="!userImage" :src="require('../assets/user_generic.svg')" width="25" />
							<img v-else :src="userImage | imageUrl('thumbnail')" />
						</div>
						<span>{{ userName }}</span>
						<span class="header-mobile-user-menu-toggle" :class="{ 'is-open': mobileUserMenu }"><inline-svg :src="require('../assets/expand.svg')" width="15"></inline-svg></span>
					</div>
					<div class="header-mobile-other">
						<HeaderUserPoints v-if="isLogged" class="activity-challenge" />

						<Notifications />

						<div v-if="isLogged && userPublic" class="header-chat">
							<router-link :to="{ name: 'Attendees' }">
								<span>
									<inline-svg :src="require('../assets/conference.svg')" width="26"></inline-svg>
								</span>
							</router-link>
						</div>

						<HeaderChat v-if="isLogged && userPublic" :static="true" />
					</div>
				</div>
				<HeaderUserMenu v-if="mobileUserMenu" class="header-user-menu-mobile" />
			</div>

			<ul>
				<li v-for="item in menu" :key="item.id">
					<HeaderNavItem :item="item"></HeaderNavItem>
					<ul v-if="item.special_type === 'exhibitionMenu'">
						<li>
							<router-link :to="{ name: 'Exhibition' }">
								<span class="text">Exhibition Hall</span>
							</router-link>
						</li>
						<li>
							<router-link :to="{ name: 'NasVillage' }">
								<span class="text">NAS Village</span>
							</router-link>
						</li>
					</ul>
					<ul v-if="item.children">
						<li v-for="item in item.children" :key="item.id">
							<HeaderNavItem :item="item" :mobile="true"></HeaderNavItem>
						</li>
					</ul>
				</li>
			</ul>
		</div>
		<div class="header-mobile-clock" :class="{ 'is-open': mobileClock }">
			<div class="mtitle">
				<inline-svg :src="require('../assets/clock.svg')" width="25" />
				<span>Congress time</span>
			</div>
			<a class="lobby-mobile-close" @click.prevent="toggleMobileClock"><inline-svg :src="require('../assets/delete.svg')" width="25"/></a>
			<div v-if="platformSettings.congressTimeBox" class="header-time">
				<strong>{{ formattedTime }} <span>CET</span></strong>
				<small v-if="showLocalTime"
					>Your local time: <strong>{{ formattedLocalTime }}</strong></small
				>
			</div>
			<div v-else-if="!platformSettings.congressTimeBox && !!platformSettings.closureCountdown && deadline >= 0" class="header-time-alt is-countdown">
				<span>Remaining days to platform closure</span>
				<strong>{{ deadline }}</strong>
			</div>
			<div>
				<Advert v-if="mobileClock" type="time-mobile" />
			</div>
		</div>
	</header>
</template>

<script>
	import HeaderNavItem from './HeaderNavItem';
	import HeaderUserPoints from './HeaderUserPoints';
	import LiveNow from './LiveNow';
	import Notifications from './Notifications';
	import { congressTimeMoment } from '../utils/congressTime.js';
	import { AUTHURL, REGISTERURL } from '../consts';
	import session from '../utils/session';
	import platformSettings from '../utils/platformSettings';
	import $http from '../utils/http';
	import HeaderChat from './HeaderChat';
	import Advert from './Advert';
	import moment from 'moment-timezone';
	import CongressTimeModal from './CongressTimeModal';
	import { eventBus } from '../utils/utils';
	import HeaderUserMenu from './HeaderUserMenu';

	export default {
		name: 'Header',
		components: {
			HeaderUserMenu,
			Advert,
			HeaderChat,
			HeaderNavItem,
			HeaderUserPoints,
			LiveNow,
			Notifications,
		},
		props: {
			menu: Array,
			info: Array,
		},
		data() {
			return {
				session: session.state,
				interval: null,
				intervalCountdown: null,
				congressTime: null,
				localTime: null,
				mobileMenu: false,
				registerUrl: REGISTERURL,
				authUrl: AUTHURL,
				platformSettings: platformSettings.state,
				image: null,
				mobileTracks: false,
				mobileClock: false,
				mobileUserMenu: false,
				tracks: [],
			};
		},
		computed: {
			formattedTime() {
				if (!this.congressTime) return false;
				return this.congressTime.format('HH:mm');
			},
			formattedLocalTime() {
				if (!this.localTime) return false;
				return this.localTime.format('LT');
			},
			showLocalTime() {
				return this.congressTime.format('Z') !== this.localTime.format('Z');
			},
			isHomepage() {
				return this.$route.name === 'Homepage';
			},
			isLogged() {
				return this.session.loggedIn;
			},
			userName() {
				return `${this.session.user.firstName} ${this.session.user.lastName}`;
			},
			userProfession() {
				if (typeof this.session.user.user_profession === 'object') {
					return this.session.user.user_profession?.name;
				}
				return '';
			},
			userCountry() {
				if (typeof this.session.user.user_country === 'object') {
					return this.session.user.user_country?.name;
				}
				return '';
			},
			userGender() {
				return this.session.user.gender;
			},
			userImage() {
				return this.session.user.image;
			},
			userId() {
				return this.session.user.eaaciNumber;
			},
			userPublic() {
				return this.session.user.public_profile;
			},
			email() {
				return this.session.user.username;
			},
			deadline() {
				if (!this.congressTime && !this.platformSettings) return false;
				if (this.platformSettings.closureCountdown) {
					let deadline = new Date(this.platformSettings.closureDate);
					let congressTime = new Date(this.congressTime);
					if (deadline && congressTime) {
						let diffTime = deadline - congressTime;
						return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
					}
				}
				return false;
			},
			hasBCB() {
				return this.session.user && this.session.user.bs_card_exchange_booths_access ? !!this.session.user.bs_card_exchange_booths_access.length : false;
			},
			hasEarlyAccess() {
				return this.session.user && this.session.user.booth_early_accesses ? !!this.session.user.booth_early_accesses.length : false;
			},
			headerLogoGraphic() {
				if (this.platformSettings.logoGraphic) {
					return this.$options.filters.imageUrl(this.platformSettings.logoGraphic);
				}
				return require('../assets/logo.png');
			},
			headerLogoText() {
				if (this.platformSettings.logoText) {
					return this.$options.filters.imageUrl(this.platformSettings.logoText);
				}
				return require('../assets/logo.svg');
			},
			footerLogoText() {
				if (this.platformSettings.footerLogoText) {
					return this.$options.filters.imageUrl(this.platformSettings.footerLogoText);
				}
				return require('../assets/logow.svg');
			},
		},
		watch: {
			$route() {
				this.closeMobileMenu();
			},
		},
		beforeMount() {
			this.updateTime();
		},
		mounted() {
			this.interval = setInterval(this.updateTime, 1000);
			this.intervalCountdown = setInterval(this.deadline, 60 * 60 * 1000);
			this.loadData();

			eventBus.$on('toggleMobileTracks', this.toggleMobileTracks);

			eventBus.$on('toggleMobileClock', this.toggleMobileClock);
		},
		beforeDestroy() {
			clearInterval(this.interval);
		},
		methods: {
			updateTime() {
				this.congressTime = congressTimeMoment();
				let localZone = moment.tz.guess();
				this.localTime = moment().tz(localZone);
			},
			logout() {
				session.logout();
			},
			toggleMobileMenu() {
				this.mobileMenu = !this.mobileMenu;
				document.body.classList.toggle('noscroll', this.mobileMenu);
			},
			closeMobileMenu() {
				this.mobileMenu = false;
				document.body.classList.toggle('noscroll', this.mobileMenu);
			},
			startTour() {
				this.$tours['dcwTour'].start();
			},
			loadData() {
				if (this.isLogged) {
					if (!this.userImage) {
						$http.get(`/user-info-light`).then(({ data }) => {
							this.image = data.image;
							session.updateImage(this.image);
						});
					}
				}
			},
			timeBoxClick() {
				this.$gtm.dataLayer().push({
					event: 'gaEvent',
					eCategory: 'No Booth Event',
					eAction: 'Clock',
					eLabel: 'Clock',
					Exhibitor: '(not set)',
					Booth: '(not set)',
				});
				this.$modal.show(
					CongressTimeModal,
					{},
					{
						classes: 'congress-time-modal',
						width: '90%',
						maxWidth: 800,
						height: 'auto',
						adaptive: true,
					}
				);
			},
			toggleMobileTracks() {
				this.mobileTracks = !this.mobileTracks;
			},
			toggleMobileClock() {
				this.mobileClock = !this.mobileClock;
			},
			toggleMobileUserMenu() {
				this.mobileUserMenu = !this.mobileUserMenu;
			},
			closeMobile() {
				this.mobileTracks = false;
				this.mobileClock = false;
			},
			setTracks(data) {
				this.tracks = data;
			},
		},
	};
</script>
