<template>
	<div v-if="active && !isClosed" class="bottom-banner">
		<span>{{ data.text }}</span>
		<a v-if="showButton" class="bottom-banner-action" :href="data.url" @click="close">{{ data.button }}</a>
		<a class="close" @click="close">&times;</a>
	</div>
</template>

<script>
	export default {
		name: 'BannerRegister',
		props: {
			data: Object,
		},
		data() {
			return {
				isClosed: false,
			};
		},
		computed: {
			active() {
				if (!this.data) return false;
				return this.data.active && !this.data.text;
			},
			showButton() {
				if (!this.data) return false;
				return !!this.data.button;
			},
		},
		mounted() {
			this.isClosed = !!window.localStorage.getItem('hide-banner-register');
		},
		methods: {
			close() {
				this.isClosed = true;
				window.localStorage.setItem('hide-banner-register', true);
			},
		},
	};
</script>
