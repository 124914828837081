<template>
	<modal name="restrict-modal" :adaptive="true" :width="600" height="auto" :reset="true">
		<div class="restrict-modal">
			<h1>Restricted Content</h1>
			<p>
				For compliance reasons the information you have selected is limited to Prescribing professionals only. For further information please contact an
				industry representative.
			</p>
		</div>
	</modal>
</template>

<script>
	export default {
		name: 'RestrictedModal',
	};
</script>

<style scoped></style>
