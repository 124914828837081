<template>
	<div>
		<h1>External link</h1>
		<p>You will be transferred to an external website.</p>
		<small
			>We do not assume responsibility or liability for the accuracy, adequacy, validity, reliability, availability, legality or completeness of any information offered by
			third-party websites linked through the platform or any website or feature linked in any banner or other advertising.</small
		>
		<div class="buttons">
			<a class="button is-primary" :href="url" :target="target && '_blank'" @click="ok">Yes, proceed</a>
			<a class="button is-primary is-outlined" @click="cancel()">No, cancel</a>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ExternalDisclaimer',
		props: {
			url: String,
			target: Boolean,
			analytics: Object,
		},
		methods: {
			cancel() {
				this.$emit('close');
			},
			ok() {
				if (this.analytics) {
					this.$gtm.dataLayer().push(this.analytics);
				}
				this.$emit('close');
			},
		},
	};
</script>
