<template>
	<div>
		<div class="banner-img"><img :src="banner.image | imageUrl" /></div>
		<div class="banner-content clearfix">
			<h1>{{ banner.title }}</h1>
			<div>
				<VueMarkdown v-if="banner.description" :source="banner.description" :prerender="prerender" />
				<Link v-if="banner.link" :link="banner.link" :analytics="analytics" class="button is-primary">{{ banner.link.text ? banner.link.text : banner.title }}</Link>
			</div>
		</div>
	</div>
</template>

<script>
	import Link from './Link';
	import VueMarkdown from 'vue-markdown-v2';
	import { prerender } from '../utils/markdown';

	export default {
		name: 'LobbyBannerModal',
		components: { Link, VueMarkdown },
		props: {
			banner: Object,
		},
		data() {
			return {};
		},
		computed: {
			analytics() {
				return {
					event: 'gaEvent',
					eCategory: 'No Booth Event',
					eAction: 'Lobby - Ads left - Popup',
					eLabel: this.banner.title ? this.banner.title : null,
					Exhibitor: this.banner.gtm_exhibitor ? this.banner.gtm_exhibitor : '(not set)',
					Booth: '(not set)',
				};
			},
		},
		methods: {
			cancel() {
				this.$emit('close');
			},
			prerender: prerender,
		},
	};
</script>
