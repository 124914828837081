import md5 from 'crypto-js/md5';
import { RESTRICTED_PLATFORM, AUTHURL } from '../consts';

const storeKey = 'dcwsession';
const storeUser = 'dcwuser';

const TOKEN = '792e921b-ec38-40f4-9d2c-c4afddb309ce';

const session = {
	state: {
		loggedIn: false,
		key: null,
		user: {},
	},
	login(data) {
		const { jwt, user: userData } = data;

		this.state.loggedIn = true;
		this.state.key = jwt;

		window.localStorage.setItem(storeKey, jwt);
		if (userData) {
			const user = [
				'id',
				'firstName',
				'lastName',
				'username',
				'gender',
				'email',
				'image',
				'eaaciNumber',
				'kitId',
				'country',
				'user_country',
				'pharmaEmployee',
				'preferredSection',
				'prescriber',
				'public_profile',
				'user_profession',
				'booth_early_accesses',
				'bs_card_exchange_booths_access',
				'eaaci_membership',
				'profile_frame_enabled',
				'created_at',
				'profileChecked',
				'event_fee',
			].reduce((result, key) => {
				result[key] = userData[key];
				return result;
			}, {});
			this.state.user = user;

			window.localStorage.setItem(storeUser, JSON.stringify(user));
		}
	},
	updateSession(data) {
		let newData = {};
		for (let key in data) {
			if (key in this.state.user) {
				newData[key] = data[key];
			}
		}
		this.state.user = { ...this.state.user, ...newData };
		window.localStorage.setItem(storeUser, JSON.stringify(this.state.user));
	},
	updateCheck(state) {
		this.state.user.profileChecked = state;
		window.localStorage.setItem(storeUser, JSON.stringify(this.state.user));
	},
	updateProfile(public_profile) {
		this.state.user.public_profile = public_profile;
		window.localStorage.setItem(storeUser, JSON.stringify(this.state.user));
	},
	updateName(firstName, lastName) {
		this.state.user.firstName = firstName;
		this.state.user.lastName = lastName;
		window.localStorage.setItem(storeUser, JSON.stringify(this.state.user));
	},
	updateImage(image) {
		this.state.user.image = image;
		window.localStorage.setItem(storeUser, JSON.stringify(this.state.user));
	},
	logout() {
		this.state.key = null;
		this.state.loggedIn = false;
		this.state.user = {};
		window.localStorage.removeItem(storeKey);
		window.localStorage.removeItem(storeUser);
		if (RESTRICTED_PLATFORM) {
			window.location = AUTHURL;
		}
	},
	checkToken() {
		if (this.state.loggedIn === false) {
			if (window.localStorage.getItem(storeKey)) {
				let jwt = window.localStorage.getItem(storeKey);
				let user = window.localStorage.getItem(storeUser);
				if (user) {
					user = JSON.parse(user);
				}
				if (jwt && user) {
					this.state.loggedIn = true;
					this.state.key = jwt;
					this.state.user = user;
				}
			}
		}
	},
	generateToken() {
		const time = new Date().getTime() + 180000;
		const payload = {
			userData: {
				uid: this.state.user.kitId,
				lastname: this.state.user.firstName,
				firstname: this.state.user.lastName,
				email: this.state.user.email,
			},
			access: ['live'],
			ttl: time,
		};

		const payloadB64 = btoa(unescape(encodeURIComponent(JSON.stringify(payload))));
		const md5Hash = md5(payloadB64 + TOKEN);
		const mainload = {
			data: payloadB64,
			checksum: md5Hash.toString(),
		};

		return btoa(JSON.stringify(mainload));
	},
};

session.checkToken();

export default session;
