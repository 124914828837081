<template>
	<div
		v-if="element"
		:class="[
			`banner${index}`,
			{
				'is-hovered': highlighted === index,
				'is-other-hovered': highlighted && highlighted !== index,
				'is-restricted': element.restricted,
				'is-none': element.type === 'none',
				'is-video': video,
			},
		]"
		@mouseenter="onMouseEnter"
		@mouseleave="onMouseLeave"
		@click="onClick"
	>
		<img v-if="image" :src="image" />
		<inline-svg v-if="svg" :src="svg"></inline-svg>
		<video v-if="video" ref="video" muted autoplay="true" loop>
			<source :src="video" :type="element.banner.mime" />
		</video>
	</div>
</template>

<script>
	export default {
		name: 'BoothElementBanner',
		props: {
			index: Number,
			elements: Array,
			highlighted: Number,
			template: String,
		},
		computed: {
			element() {
				if (!this.elements) return;
				return this.elements.find(e => e.element === `element_${this.index}`);
			},
			image() {
				if (!this.element?.banner) return;
				if (!this.svg && this.element.banner.mime.match('image.*')) {
					return this.$options.filters.imageUrl(this.element.banner);
				}
				return false;
			},
			svg() {
				if (!this.element?.banner) return;
				if (this.element.banner.mime === 'image/svg+xml') {
					return this.$options.filters.imageUrl(this.element.banner);
				}
				return false;
			},
			video() {
				if (!this.element?.banner) return;
				if (this.element.banner.mime.match('video.*')) {
					return this.$options.filters.imageUrl(this.element.banner);
				}
				return false;
			},
		},
		methods: {
			onMouseEnter() {
				this.$emit('enter', this.index);
				if (this.video) {
					this.$refs.video.muted = false;
					this.$refs.video.play().catch(() => {
						this.$refs.video.muted = true;
						this.$refs.video.play();
					});
				}
			},
			onMouseLeave() {
				this.$emit('leave', this.index);
				if (this.video) {
					this.$refs.video.muted = true;
					this.$refs.video.play();
				}
			},
			onClick() {
				this.$emit('banner-click', this.index);
			},
		},
	};
</script>
