<template> </template>

<script>
	import Vue from 'vue';
	import $http from '../utils/http.js';
	import session from '../utils/session';
	import { PROVIDER } from '../consts';
	import LoginFailModal from '../components/LoginFailModal';
	import { CometChat } from '@cometchat-pro/chat';
	import platformSettings from '../utils/platformSettings';
	import { CometChatManager } from '../cometchat/src/util/controller';

	export default {
		name: 'Auth',
		created() {
			platformSettings.loader(true);
			let afterLogin = window.sessionStorage.getItem('afterLogin');
			let params = window.location.href.split('auth?');
			if (params.length === 2) {
				$http.get(`/auth/${PROVIDER}/callback?${params[1]}`).then(
					data => {
						session.login(data.data);
						if (afterLogin) {
							window.sessionStorage.removeItem('afterLogin');
							afterLogin = JSON.parse(afterLogin);
							this.$router.replace({ name: afterLogin.name, params: afterLogin.params });
						} else {
							this.$router.replace({ name: 'Homepage' });
						}

						CometChat.getLoggedInUser().then(
							() => {},
							() => {
								$http.get('/chat-auth/').then(({ data }) => {
									CometChat.login(data.auth).then(
										User => {
											//console.log('Login Successful:', { User });
										},
										error => {
											//console.log('Login failed with exception:', { error });
										}
									);
								});
							}
						);
					},
					() => {
						this.$router.replace({ name: 'Homepage' });
						Vue.prototype.$modal.show(
							LoginFailModal,
							{},
							{
								classes: 'login-modal',
								width: '90%',
								maxWidth: 550,
								height: 'auto',
								adaptive: true,
							},
							{
								'before-close': () => {
									this.$Progress.finish();
								},
							}
						);
					}
				);
			} else {
				window.alert('');
				this.$router.replace({ name: 'Homepage' });
			}
		},
	};
</script>
