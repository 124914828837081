<template>
	<modal name="booth-rating" @before-open="beforeOpen" :adaptive="true" width="90%" :max-width="700" height="auto" :reset="true">
		<div class="booth-rating-modal">
			<h1>Rate &amp; Review</h1>
			<p>Please rate the content and quality of the information provided</p>
			<VueStars v-model="stars" :max="5">
				<inline-svg slot="activeLabel" :src="require('../assets/star_filled.svg')" width="38"></inline-svg>
				<inline-svg slot="inactiveLabel" :src="require('../assets/star_empty.svg')" width="38"></inline-svg>
			</VueStars>
			<textarea v-model="message" class="textarea" placeholder="Provide any comment you may have..."></textarea>
			<div class="buttons">
				<button class="button is-primary" :disabled="stars < 1 || submitting" :class="{ 'is-loading': submitting }" @click.prevent="submit">Submit review</button>
				<a class="button is-primary is-outlined" @click.prevent="cancel()">Cancel</a>
			</div>
		</div>
	</modal>
</template>

<script>
	import { VueStars } from 'vue-stars';
	import $http from '../utils/http';

	export default {
		name: 'BoothRating',
		components: { VueStars },
		data() {
			return {
				id: null,
				stars: 0,
				message: '',
				submitting: false,
			};
		},
		methods: {
			beforeOpen(event) {
				this.id = event.params.id;
			},
			submit() {
				this.submitting = true;
				$http
					.post('/booth-ratings', {
						booth: this.id,
						stars: this.stars,
						review: this.message,
					})
					.then(data => {
						this.submitting = false;
						this.$parent.boothRated();
						this.$modal.hide('booth-rating');
					});
			},
			cancel() {
				this.$modal.hide('booth-rating');
			},
		},
	};
</script>

<style scoped></style>
