<template>
	<div v-click-outside="hideOldNotifications" class="notifications">
		<a :class="{ 'is-active': isOpened }" @click.prevent="toggleOldNotifications">
			<span><inline-svg :src="require('../assets/ring.svg')" width="25"></inline-svg></span>
			<span class="title">Notifications</span>
		</a>
		<div class="notifications-new">
			<NotificationToast v-for="notification in newNotifications" :key="notification.id" :data="notification" />
		</div>
		<div :class="{ 'is-opened': isOpened }" class="notifications-old">
			<div v-if="notifications.length > 0" class="in">
				<Notification v-for="notification in notifications" :key="notification.id" :data="notification" @notification:link:click="hideOldNotifications" />
			</div>
			<div v-else class="notification is-center">No notifications available</div>
		</div>
	</div>
</template>

<script>
	import $http from '../utils/http';
	import Notification from './Notification';
	import NotificationToast from './NotificationToast';
	import session from '../utils/session';

	export default {
		name: 'Notifications',
		components: {
			Notification,
			NotificationToast,
		},
		data() {
			return {
				isOpened: false,
				interval: null,
				intervalTimeout: 300000,
				maxNewNotifications: 4,
				notifications: [],
				newNotifications: [],
				session: session.state,
			};
		},

		computed: {
			isLogged() {
				return this.session.loggedIn;
			},

			preferredSection() {
				return this.session.user.preferredSection;
			},
		},

		created() {
			this.getNotifications();
		},

		mounted() {
			this.interval = setInterval(() => {
				this.getNotifications();
			}, this.intervalTimeout);
		},

		beforeDestroy() {
			clearInterval(this.interval);
		},

		methods: {
			getNotifications() {
				const now = new Date();

				$http.get(`/notifications?_sort=duration_from:ASC`).then(({ data }) => {
					data = data
						.filter(({ duration_from, duration_to }) => duration_from < now.toISOString() && duration_to > now.toISOString())
						.filter(({ platform }) => platform === 'EAACI')
						.filter(({ preferred_section }) => !preferred_section || preferred_section === this.preferredSection)
						.filter(({ visibility }) => visibility === 'all' || (visibility === 'guests' && !this.isLogged) || (visibility === 'delegates' && this.isLogged));

					this.notifications = data.reverse();

					const closedNotification = JSON.parse(localStorage.getItem('closedNotification')) || [];
					this.newNotifications = data.filter(notification => !closedNotification.some(id => id === notification.id)).slice(0, this.maxNewNotifications);
				});
			},

			hideOldNotifications() {
				this.isOpened = false;
			},

			toggleOldNotifications() {
				this.isOpened = !this.isOpened;
			},
		},
	};
</script>
